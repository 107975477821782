import { Button, Modal } from "react-bootstrap";
import React, {useState} from "react";
const ModalFeriado = (props) => {
  const {
    estado,
    onCloseModal,
    item,
    onReturnValue,
    isnew
  } = props;
  const handleSubmit2 = (event) => {
    event.preventDefault();
    formData.fecha = formData.fecha ? formData.fecha : item.fecha;
    formData.fechanombre = formData.fechanombre ? formData.fechanombre : item.fechanombre;
    formData.idcalendarioferiado = formData.idcalendarioferiado ? formData.idcalendarioferiado : item.idcalendarioferiado;
    onReturnValue(formData,isnew)
  };
  const [formData, setFormData] = useState([]);
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Hora Extra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Fecha
                <span className="text-danger">*</span>
                <input
                  type="date"
                  className="form-control"
                  required
                  defaultValue={item.fecha}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      fecha: e.target.value,
                    });
                  }}
                />
              </label>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
              Nombre Fecha 
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  required
                  defaultValue={item.fechanombre}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                     fechanombre: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalFeriado;
