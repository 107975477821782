import { useRef } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { alergiasoptions } from "./docs/data.ts";

const ModalCliente = (props) => {
  const { estado, typeModal, onCloseModal, idpaciente, onReturnValue } = props;
  const [formData3, setValue] = useState([]);

  const [InputValue, setInputValue] = useState([]);
  const pacienteRef = useRef();
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(false);
  let fecha = new Date(idpaciente[0].FechaNacimiento);
  fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset());
  const handleSubmit = (event) => {
    event.preventDefault();
    setShow(!show);
    setShow2(!show2);
  };

  const updateselect = (newValue) => {
    console.log(newValue);
    setValue({ ...formData3, alergiasamedicamentos: newValue.value });
  };
  const updateselect2 = (newValue) => {
    console.log(newValue);
    setValue({ ...formData3, alergiasgral: newValue.value });
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();

    // formData.Celular = formData2.Celular ? formData.Celular : "Hombre"; //en vez de hombre poner {item.Celular}
    // formData.Ciudad = formData2.Ciudad ? formData.Ciudad : "SI"; //en vez de hombre poner {item.Ciudad} y agregar a formdata3
    formData3.nivel = formData3.nivel ? formData3.nivel : idpaciente[0].nivel;
    formData3.Cedula = formData3.Cedula
      ? formData3.Cedula
      : idpaciente[0].Cedula;
    formData3.CedulaResponsable = formData3.CedulaResponsable
      ? formData3.CedulaResponsable
      : idpaciente[0].CedulaResponsable;
    formData3.Celular = formData3.Celular
      ? formData3.Celular
      : idpaciente[0].Celular;
    formData3.Ciudad = formData3.Ciudad
      ? formData3.Ciudad
      : idpaciente[0].Ciudad;
    formData3.Correo = formData3.Correo
      ? formData3.Correo
      : idpaciente[0].Correo;
    formData3.Direccion = formData3.Direccion
      ? formData3.Direccion
      : idpaciente[0].Direccion;
    formData3.Direccionserv = formData3.Direccionserv
      ? formData3.Direccionserv
      : idpaciente[0].Direccionserv;
    formData3.Edad = formData3.Edad ? formData3.Edad : idpaciente[0].Edad;
    formData3.FamiliarResponsable = formData3.FamiliarResponsable
      ? formData3.FamiliarResponsable
      : idpaciente[0].FamiliarResponsable;
    formData3.FechaNacimiento = formData3.FechaNacimiento
      ? formData3.FechaNacimiento
      : idpaciente[0].FechaNacimiento;
    formData3.Guardiahs = formData3.Guardiahs
      ? formData3.Guardiahs
      : idpaciente[0].Guardiahs;
    formData3.IdPacientes = formData3.IdPacientes
      ? formData3.IdPacientes
      : idpaciente[0].IdPacientes;
    formData3.NombrePaciente = formData3.NombrePaciente
      ? formData3.NombrePaciente
      : idpaciente[0].NombrePaciente;
    formData3.RazonSocial = formData3.RazonSocial
      ? formData3.RazonSocial
      : idpaciente[0].RazonSocial;
    formData3.Ruc = formData3.Ruc ? formData3.Ruc : idpaciente[0].Ruc;
    formData3.Sexo = formData3.Sexo ? formData3.Sexo : idpaciente[0].Sexo;
    formData3.Telefono = formData3.Telefono
      ? formData3.Telefono
      : idpaciente[0].Telefono;
    formData3.altura = formData3.altura
      ? formData3.altura
      : idpaciente[0].altura;
    formData3.diagnosticoMedico = formData3.diagnosticoMedico
      ? formData3.diagnosticoMedico
      : idpaciente[0].diagnosticoMedico;
    formData3.enfermedadBase = formData3.enfermedadBase
      ? formData3.enfermedadBase
      : idpaciente[0].enfermedadBase;
    formData3.equipoRehabilitacion = formData3.equipoRehabilitacion
      ? formData3.equipoRehabilitacion
      : idpaciente[0].equipoRehabilitacion;
    formData3.formaAlimentacion = formData3.formaAlimentacion
      ? formData3.formaAlimentacion
      : idpaciente[0].formaAlimentacion;
    formData3.movilidadPropia = formData3.movilidadPropia
      ? formData3.movilidadPropia
      : idpaciente[0].movilidadPropia;
    formData3.peso = formData3.peso ? formData3.peso : idpaciente[0].peso;
    formData3.nivel = formData3.nivel ? formData3.nivel : idpaciente[0].nivel;
    formData3.tiempoAsistencia = formData3.tiempoAsistencia
      ? formData3.tiempoAsistencia
      : idpaciente[0].tiempoAsistencia;
    formData3.obs1 = formData3.obs1 ? formData3.obs1 : idpaciente[0].obs1;
    formData3.comoconocio = formData3.comoconocio
      ? formData3.comoconocio
      : idpaciente[0].comoconocio;
    formData3.obs2 = formData3.obs2 ? formData3.obs2 : idpaciente[0].obs2;
    formData3.alergiasamedicamentos = formData3.alergiasamedicamentos
      ? formData3.alergiasamedicamentos
      : idpaciente[0].alergiasamedicamentos;
    formData3.alergiasgral = formData3.alergiasgral
      ? formData3.alergiasamedicamentos
      : idpaciente[0].alergiasgral;
    formData3.viadealimentacion = formData3.viadealimentacion
      ? formData3.viadealimentacion
      : idpaciente[0].viadealimentacion;
    formData3.tarifa = formData3.tarifa
      ? formData3.tarifa
      : idpaciente[0].tarifa;
    onReturnValue(formData3);
  };

  if (typeModal === "EDITAR") {
    return (
      <>
        {idpaciente.map((item) => {
          return (
            <div key={item.Correo}>
              <Modal show={estado} onHide={onCloseModal} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title>Actualizar Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {show && (
                    <div className="card">
                      <div className="card-body">
                        <div className="form-validation">
                          <form
                            className="form-valide"
                            action="#"
                            method="post"
                            onSubmit={handleSubmit}
                            id="Clienteform"
                          >
                            <Row>
                              <Col>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Nombre Completo
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      placeholder="Nombre"
                                      name="NombrePaciente"
                                      Value={item.NombrePaciente}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          NombrePaciente: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    CI
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="">
                                    <input
                                      type="number"
                                      className="form-control"
                                      required
                                      placeholder="CI"
                                      name="Cedula"
                                      Value={item.Cedula}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Cedula: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Telefono
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      pattern="[0-9]{7,12}"
                                      title="Ingrese Numero de telefono Valido "
                                      placeholder="Telefono"
                                      name="telefono"
                                      Value={item.Telefono}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Telefono: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Correo
                                  </label>
                                  <div className="">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Correo"
                                      name="Correo"
                                      Value={item.Correo}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Correo: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Ciudad
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ciudad"
                                      name="ciudad"
                                      Value={item.Ciudad}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Ciudad: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Direccion
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Direccion"
                                      name="direccion"
                                      Value={item.Direccion}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Direccion: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Responsable
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Responsable"
                                      name="responsable"
                                      required
                                      Value={item.FamiliarResponsable}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          FamiliarResponsable: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    CI del Responsable
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="CI del Responsable"
                                      name="ciresponsable"
                                      required
                                      Value={item.CedulaResponsable}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          CedulaResponsable: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Numero del Responsable
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      pattern="[0-9]{7,12}"
                                      title="Ingrese Numero de telefono Valido "
                                      placeholder="Numero del Responsable"
                                      name="nresponsable"
                                      Value={item.Celular}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Celular: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Razon social
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Razon Social"
                                      name="razonsocial"
                                      Value={item.RazonSocial}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          RazonSocial: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Ruc
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ruc"
                                      name="ruc"
                                      Value={item.Ruc}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Ruc: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Tarifa
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Tarifa"
                                      name="tarifa"
                                      Value={item.tarifa}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          tarifa: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2  row ">
                                  <label className="form-label">nivel</label>
                                  <div className="">
                                    <select
                                      className="form-control"
                                      id="val-skill"
                                      name="nivel"
                                      defaultValue={item.nivel}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          nivel: e.target.value,
                                        });
                                      }}
                                    >
                                      <option
                                        value="Nivel 1"
                                        style={{ color: "green" }}
                                      >
                                        Nivel 1
                                      </option>
                                      <option
                                        value="Nivel 2"
                                        style={{ color: "orange" }}
                                      >
                                        Nivel 2
                                      </option>
                                      <option
                                        value="Nivel 3"
                                        style={{ color: "red" }}
                                      >
                                        Nivel 3
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-form-label">
                                    Observacion
                                  </label>
                                  <div className="">
                                    <textarea
                                      name="obs1"
                                      value={item.obs1}
                                      className="form-control"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          obs1: e.target.value,
                                        });
                                        item.obs1 = e.target.value;
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-form-label"
                                    htmlFor="val-password"
                                  >
                                    Como conocio Eethsa
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="comoconocioeethsa"
                                      Value={item.comoconocio}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          comoconocio: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {show2 && (
                    <div className="card">
                      <div className="card-body">
                        <div className="form-validation">
                          <form
                            className="form-valide"
                            action="#"
                            method="post"
                            onSubmit={handleSubmit2}
                            id="Clienteform2"
                          >
                            <Row>
                              <Col>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Fecha de Nacimiento
                                  </label>
                                  <div className="form-group mb-2 row">
                                    <input
                                      type="date"
                                      className="form-control"
                                      Value={item.FechaNacimiento}
                                      name="Fechadeingreso"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          FechaNacimiento: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Peso (en Kg)
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Peso"
                                      name="peso"
                                      pattern="(?<=^| )\d+(\.\d+)?(?=$| )"
                                      title="Ingrese valor en Kg ejemplo (75.80)"
                                      Value={item.peso}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          peso: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Altura (en m )
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Altura"
                                      name="altura"
                                      pattern="(?<=^| )\d+(\.\d+)?(?=$| )"
                                      title="Ingrese valor en m ejemplo (1.80)"
                                      Value={item.altura}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          altura: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2  row ">
                                  <label className="form-label">Sexo</label>
                                  <div className="">
                                    <select
                                      className="form-control"
                                      id="val-skill"
                                      name="sexo"
                                      defaultValue={item.Sexo}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Sexo: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="Hombre">Hombre</option>
                                      <option value="Mujer">Mujer</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Diagnostico Medico
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Diagnostico Medico"
                                      name="diagnosticomedico"
                                      Value={item.diagnosticoMedico}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          diagnosticoMedico: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Enfermedades de Base
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enfermedades de Base"
                                      name="enfermedadesdebase"
                                      Value={item.enfermedadBase}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          enfermedadBase: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2  row ">
                                  <label className="form-label">
                                    Movilidad
                                  </label>

                                  <div className="">
                                    <select
                                      className="form-control"
                                      id="val-skill"
                                      name="mpropia"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          movilidadPropia: e.target.value,
                                        });
                                      }}
                                      defaultValue={item.movilidadPropia}
                                    >
                                      <option value="No Asistida">
                                        No Asistida
                                      </option>
                                      <option value="Asistida">Asistida</option>
                                      <option value="Dependiente">
                                        Dependiente
                                      </option>
                                      <option value="Semi Independiente">
                                        Semi Independiente
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Equipo de Rehabilitacion
                                  </label>
                                  <div className="">
                                    <select
                                      className="form-control"
                                      id="val-skill"
                                      name="equipoderehabilitacion"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          equipoRehabilitacion: e.target.value,
                                        });
                                      }}
                                      defaultValue={item.equipoRehabilitacion}
                                    >
                                      <option value="Sin Equipo">
                                        Sin Equipo
                                      </option>
                                      <option value="Baston">Baston</option>
                                      <option value="Silla de Ruedas">
                                        Silla de Ruedas
                                      </option>
                                      <option value="Andador">Andador</option>
                                      <option value="Grua">Grua</option>
                                    </select>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Via de alimentación
                                  </label>
                                  <div className="">
                                    <select
                                      className="form-control"
                                      id="val-skill"
                                      name="viadealimentacion"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          viadealimentacion: e.target.value,
                                        });
                                      }}
                                      defaultValue={item.viadealimentacion}
                                    >
                                      <option value="Oral">Oral</option>
                                      <option value="Gastrotomia">
                                        Gastrotomia
                                      </option>
                                      <option value="Sonda Nagastrica">
                                        Sonda Nagastrica
                                      </option>
                                      <option value="Sonda Nasoyeyunal">
                                        Sonda Nasoyeyunal
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Forma de Alimentacion
                                  </label>
                                  <div className="">
                                    <select
                                      className="form-control"
                                      id="val-skill"
                                      name="formadealimentacion"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          formaAlimentacion: e.target.value,
                                        });
                                      }}
                                      defaultValue={item.formaAlimentacion}
                                    >
                                      <option value="No Asistida">
                                        No Asistido
                                      </option>
                                      <option value="Asistida">Asistido</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group mb-2  row ">
                                  <label className="form-label">
                                    Alergias a Medicamentos
                                  </label>
                                  <div className=" mb-2">
                                    <CreatableSelect
                                      options={alergiasoptions}
                                      onChange={(newValue) =>
                                        updateselect(newValue)
                                      }
                                      onInputChange={(newValue) =>
                                        setInputValue(newValue)
                                      }
                                      placeholder={item.alergiasamedicamentos}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-2  row ">
                                  <label className="form-label">
                                    Alergias en Gral.
                                  </label>
                                  <div className=" mb-2">
                                    <CreatableSelect
                                      options={alergiasoptions}
                                      onChange={(newValue) =>
                                        updateselect2(newValue)
                                      }
                                      onInputChange={(newValue) =>
                                        setInputValue(newValue)
                                      }
                                      placeholder={item.alergiasgral}
                                    />
                                  </div>
                                </div>

                                <div className="form-group mb-2 row">
                                  <label className="col-form-label">
                                    Tiempo de Asistencia
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Tiempo de Asistencia"
                                      name="tiempodeasistencia"
                                      Value={item.tiempoAsistencia}
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          tiempoAsistencia: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-form-label">
                                    Modalidad de guarda (Hs).
                                  </label>
                                  <div className="">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Hs."
                                      name="hs"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Guardiahs: e.target.value,
                                        });
                                      }}
                                      Value={item.Guardiahs}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-form-label">
                                    Direccion Para el Servicio
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Direccion"
                                      name="direccionserv"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          Direccionserv: e.target.value,
                                        });
                                      }}
                                      Value={item.Direccionserv}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-form-label">
                                    Observacion
                                  </label>
                                  <div className="">
                                    <textarea
                                      name="obs2"
                                      value={item.obs2}
                                      className="form-control"
                                      onChange={(e) => {
                                        setValue({
                                          ...formData3,
                                          obs2: e.target.value,
                                        });
                                        item.obs2 = e.target.value;
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" onClick={onCloseModal}>
                    Salir
                  </Button>
                  {show && (
                    <Button type="Submit" form="Clienteform">
                      Siguiente
                    </Button>
                  )}
                  {show2 && (
                    <Button type="submit" form="Clienteform2">
                      Guardar
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          );
        })}
      </>
    );
  }
};
export default ModalCliente;
