import { ColumnFilter } from "./ColumnFilter";

export const CLIENTE_COLUMNS = [
  {
    Header: "Usuario",
   
    Filter: ColumnFilter,
  },
  {
    Header: "Fecha Entrada",
   
    Filter: ColumnFilter,
  },
  {
    Header: "Hora Entrada",
   
    Filter: ColumnFilter,
  },
  {
    Header: "Ubicacion",
  
    Filter: ColumnFilter,
  },
  {
    Header: "Fecha Salida",
    Footer: "Correo",
   
    Filter: ColumnFilter,
  },
  {
    Header: "Hora salida",
   
    Filter: ColumnFilter,
  },
  {
    Header: "Total horas",
   
    Filter: ColumnFilter,
  },
  
];

