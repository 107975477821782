import Swal from "sweetalert2";
import { useLocation} from "react-router-dom";
import {  Button, Table} from "react-bootstrap";
import React, {  useState, useReducer,useCallback,useEffect} from "react";

import { useHistory } from 'react-router-dom';
import ModalGenerarLiquidacion from './ModalGenerarLiquidacion.js'
import { GenerarLiquidacion,GetliquidacionPaciente} from "../../../api/marcacionApi.js";

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "2":
      return {
        isShowAsignarUsuario: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "3":
      return {
        isShowAsignarUsuarioEdit: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
      case "4":
        return {
          isShowDescargaMarcaciones: true,
          typeModal: state.typeModal,
          onReturnValue: state.onReturnValue,
        };
    case "END":
      return {
        ...curShow,
        isShow: false,
        isShowAsignarUsuario: false,
        isShowAsignarUsuarioEdit: false,
        isShowDescargaMarcaciones: false
      };
    case "default":
    default:
      return;
  }
};
  
const Liquidacion = () => {
  const history = useHistory();
  const [Valor, setValue] = useState();
  const [data, setData ]= useState([]);
  
  const location = useLocation();
  const items = location.state
  const [Tarifa, setTarifa] = useState(items.tarifa);
  const [ModalData, setModalData] = useState();
  
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShowAsignarUsuario: false,
    isShowAsignarUsuarioEdit: false,
    isShowDescargaMarcaciones: false,
    typeModal: null,
    onReturnValue: null,
  });
  
  const handleShow = async () => {
    // loading button 
    // traer los legajos que tengan vacio o  NULL en idliquidacion y del paciente
    let idp = items.IdPacientes
    if (idp === undefined || Valor === undefined) {
      Swal.fire({
        title: 'Paciente o Fecha Invalida',
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: true,
      });
    } else {
      const data = await GenerarLiquidacion({idp,Valor})
      if (data.length === 0) {
        Swal.fire({
          title: 'No se Encontraron Datos',
          allowOutsideClick: false,
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
        });
      } else {
        setModalData(data);
        dispatchReducer({ type: "SHOW", typeModal: "EDITAR" });
      }
    }
  };
  
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  
  const ReturnGenerarLiquidacion = async(marcarlegajo) => {
    dispatchReducer({ type: "END" });
    assigmentValue()
  };
  
  const SelectRow = (id) => {
    let nombrepaciente =   items.NombrePaciente
    history.push('/FichaLiquidacion', {id,nombrepaciente});
  };
  // Generar Liquidacion
  const assigmentValue = useCallback(async () => {
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const data2= await GetliquidacionPaciente(items.IdPacientes)
      setData([data, ...data2])
      Swal.close();
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  }, [data, items.IdPacientes]);
  
  useEffect(() => {
    assigmentValue();
  }, []); 

  // traer liquidaciones generadas 2
  // generar liquidaciones 1
  // eliminar liquidacion 4
  // Pagar liquidacion 3
  // si estado es pagado no se puede eliminar 
  // Pagar liquidacion 3
  return (
    <>
      <div key={items.IdPacientes}>
        <div className="col-xl-12">
          <div className="page-titles acti-space">
            <h2 className="heading sm-mb-0 mb-2">{items.NombrePaciente}</h2>
            <div className="d-flex align-items-center flex-wrap">
              <div className="me-2">
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Liquidaciones del usuario del servicio</h4>
                <div>
                  <input
                    type="date"
                    className="form-control mb-2"
                    onChange={(e) => {
                      setValue(e.target.value );
                    }}
                  />
                  <Button onClick={handleShow}>Generar Liquidacion</Button>
                </div>
              </div>
              <div className="card-body p-4">
                <Table responsive className="primary-table-bordered" striped hover={true}  style={{cursor:'pointer'}}>
                  <thead className="thead-primary">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Fecha Inicio</th>
                      <th scope="col">Fecha fin</th>
                      <th scope="col">Total hs</th>
                      <th scope="col">Tarifa Total</th>
                      <th scope="col">Generado por</th>
                      <th scope="col">Generado el</th>
                      <th scope="col">Estado</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    {data.slice(1).map((item, index) => (
                      <tr key={index} onClick={() => SelectRow(item.idLiquidacionClientes)}>
                        <td>{item.idLiquidacionClientes}</td>
                        <td>{item.fechainicio}</td>
                        <td>{item.fechafin}</td>
                        <td>{item.totalhs}</td>
                        <td>{item.totaltarifa}</td>
                        <td>{item.UsuarioCreacion}</td>
                        <td>{item.FechaCreacion}</td>
                        <td>{item.estado}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reducer.isShow && (
        <ModalGenerarLiquidacion
          estado={reducer.isShow}
          typeModal={reducer.typeModal}
          onCloseModal={onCloseModal}
          ModalData={ModalData}
          fechafin={Valor}
          Tarifa={Tarifa}
          onReturnValue={ReturnGenerarLiquidacion}
          handleShow={handleShow}
        />
      )}
    </>
  );
};

export default Liquidacion;
