import { CALL_API } from "../context/constValues";
import { getAuthToken } from "../context/auth-context";

export async function getAllTipoUsuarios() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/tipo-usuario`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
