/// Menu
import Metismenu from "metismenujs";
import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import FichaCliente from "../../pages/Fichaclientes";
import { getUserCredentials } from "../../../api/usuarioApi";
import { TYPE_USER } from "../../../utils/Const";
import Liquidacion from "../../pages/liquidacion/Liquidacion";
import Liquidaciones from "../../pages/Liquidaciones";

//import { add } from "date-fns";

//import profile from "../../../images/user.jpg";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [userData, setUserData] = useState({});

  const assigmentValue = useCallback(async () => {
    try {
      const result = await getUserCredentials();
      setUserData(result);
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
      "dashboard",
      "dashboard-dark",
      "banking",
      "ticketing",
      "crypto",
      "invoice",
      "contact",
      "kanban",
      //"task",
    ],
    file = [
      "file-manager",
      "user",
      "calendar",
      "to-do-list",
      "chat",
      "activity",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    marcacion = ["marcacion"],
    usuarios = ["usuarios", "fichausuario"],
    liquidaciones = ["liquidaciones"],
    clientes = ["clientes", "Fichaclientes","Liquidacion","FichaLiquidacion"],
    Sesiones = ["sesiones"],
    Opciones= ["opciones"],
    forms = [
      "form-element",
      "form-wizard",
      "form-ckeditor",
      "form-pickers",
      "form-validation",
    ],
    table = [
      "datatable-filtering",
      "datatable-sorting",
      "table-bootstrap-basic",
      //"table-datatable-basic"
    ],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${marcacion.includes(path) ? "mm-active" : ""}`}>
            <Link to="marcacion" className="ai-icon">
              <i className="material-icons"> badge </i>
              <span className="nav-text">Marcacion</span>
            </Link>
          </li>
          {userData.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id && (
            <li className={`${usuarios.includes(path) ? "mm-active" : ""}`}>
              <Link to="usuarios" className="ai-icon">
                <i className="material-icons"> person </i>
                <span className="nav-text">Cuentas</span>
              </Link>
            </li>
          )}
          {userData.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id && (
            <li className={`${clientes.includes(path) ? "mm-active" : ""}`}>
              <Link to="clientes" className="ai-icon">
                <i className="material-icons"> person_add </i>
                <span className="nav-text">Usuarios</span>
              </Link>
            </li>
          )}
          {userData.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id && (
            <li className={`${Sesiones.includes(path) ? "mm-active" : ""}`}>
              <Link to="sesiones" className="ai-icon">
                <i className="material-icons">check_circle </i>
                <span className="nav-text">Sesiones</span>
              </Link>
            </li>
          )}
          {userData.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id && (
            <li className={`${liquidaciones.includes(path) ? "mm-active" : ""}`}>
              <Link to="liquidaciones" className="ai-icon">
                <i className="material-icons"> description </i>
                <span className="nav-text">Liquidaciones</span>
              </Link>
            </li>
          )}
           {userData.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id && (
            <li className={`${Opciones.includes(path) ? "mm-active" : ""}`}>
              <Link to="opciones" className="ai-icon">
                <i className="material-icons"> settings </i>
                <span className="nav-text">Opciones</span>
              </Link>
            </li>
          )}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
