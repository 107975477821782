import { useCallback,useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from 'moment'
const ModalHoraExtra = (props) => {
  const {
    estado,

    onCloseModal,
    item,
    onReturnValue,
  } = props;
  const [horaextra,setHoraExtra] = useState(item.HoraExtra);
  const [horashoraextra,setHorasHoraExtra] = useState(item.horaextramaximo);
  const [IDLEGAJO,setidlegajo]=useState(item.IdLegajoEnfermeria);
  const [EstaConfirmado,setEstaConfirmado]=useState(item.estaConfirmado.data[0]);
  const [Formdata3,setValue]=useState(item)
  const assigmentValue = useCallback(async () => {
   
    console.log(horashoraextra)
    var hours = Math.floor(horashoraextra);
    if (hours == 0)
    {
      hours= "00"
    }
    if (hours == 1)
    {
      hours= "01"
    }
    if (hours == 2)
    {
      hours= "02"
    }
    if (hours == 3)
    {
      hours= "03"
    }
    if (hours == 4)
    {
      hours= "04"
    }
    if (hours == 5)
    {
      hours= "05"
    }
    if (hours == 6)
    {
      hours= "06"
    }
    if (hours == 7)
    {
      hours= "07"
    }
    if (hours == 8)
    {
      hours= "08"
    }
    if (hours == 9)
    {
      hours= "09"
    }
    var minutes = Math.round((horashoraextra % 1)*100)/100;
    if (minutes == 0)
    {
      minutes= "00"
    }
    if (minutes == 1)
    {
      minutes= "01"
    }
    if (minutes == 2)
    {
      minutes= "02"
    }
    if (minutes == 3)
    {
      minutes= "03"
    }
    if (minutes == 4)
    {
      minutes= "04"
    }
    if (minutes == 5)
    {
      minutes= "05"
    }
    if (minutes == 6)
    {
      minutes= "06"
    }
    if (minutes == 7)
    {
      minutes= "07"
    }
    if (minutes == 8)
    {
      minutes= "08"
    }
    if (minutes == 9)
    {
      minutes= "09"
    }
    let time= (hours + ':' + minutes * 60); 
    setHorasHoraExtra(time)
  }, []);

  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  const handleSubmit2 = (event) => {
    let formdata= ({horaextra,horashoraextra,IDLEGAJO,EstaConfirmado})
    event.preventDefault();
    onReturnValue(formdata,Formdata3);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Hora Extra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Habilitar Hora Extra?
                <span className="text-danger">*</span>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setHoraExtra(e.target.value);
                  }}
                  defaultValue={horaextra}
                >
                  <option value="True">Si</option>
                  <option value="False">No</option>
                </select>
              </label>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Cantidad Horas extra
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="time"
                  className="form-control"
                  required
                 value={horashoraextra}
                  onChange={(e) => {
                    setHorasHoraExtra(
                      e.target.value
                    );
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalHoraExtra;
