import React,{useState, useContext, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";



//Import Components
import { ThemeContext } from "../../../context/ThemeContext";


//images
//import small1 from './../../../images/profile/small/pic1.jpg';



const CharacterData = [
	{ svgColor:'#FFD125' ,changeClass:'up' , title:'Income'},
	{ svgColor:'#FCFCFC',  title:'Expense'},
];

const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

	const [startDate, setStartDate] = useState(new Date());
	const [selectBtn, setSelectBtn] = useState("This Month");
	const [selectBtn2, setSelectBtn2] = useState("This Month");
	

	
	return(
		<>
		</>
	)
}
export default Home;