import { useRef, useState, useEffect } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { useDownloadExcel } from "react-export-table-to-excel";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { CALL_IMAGE } from "../../../../context/constValues";
import { UploadAnimation } from "../animations/UploadAnimation.js";
const ModalPreviewUsuario = (props) => {
  const { estado, onCloseModal, datos, onReturnValue } = props;
  const referencia = useRef();
  const uploadFiles = () => {
    referencia.current.click();
  };
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setPreview("");
    }
  });

  useEffect(() => {
    const {
      Usuario,
      password,
      tipousuario,
      subtipo,
      Fechaingreso,
      registroprofesional,
      Nombre,
      Cedula,
      sexo,
      estadocivil,
      hijos,
      Fechadenacimiento,
      antpol,
      Telefono,
      departamento,
      Ciudad,
      barrio,
      Direccion,
      imagen,
    } = datos[0];
    setFormData({
      Usuario: Usuario && null,
      password: password && null,
      tipousuario: tipousuario && null,
      subtipo: subtipo && null,
      Fechaingreso: Fechaingreso && null,
      registroprofesional: registroprofesional && null,
      Nombre: Nombre && null,
      Cedula: Cedula && null,
      sexo: sexo && null,
      estadocivil: estadocivil && null,
      hijos: hijos && null,
      Fechadenacimiento: Fechadenacimiento && null,
      antpol: antpol && null,
      Telefono: Telefono && null,
      departamento: departamento && null,
      Ciudad: Ciudad && null,
      barrio: barrio && null,
      Direccion: Direccion && null,
      imagen: imagen && null,
    });
  }, [datos]);

  const [formData, setFormData] = useState([]);
  const onSubmitUsuario = (e) => {
    e.preventDefault();
    formData.Usuario = formData.Usuario ? formData.Usuario : datos[0].Usuario;
    formData.tipousuario = formData.tipousuario
      ? formData.tipousuario
      : datos[0].IdTipoUsuario;
    formData.subtipo = formData.subtipo ? formData.subtipo : datos[0].subtipo;
    formData.Fechaingreso = formData.Fechaingreso
      ? formData.Fechaingreso
      : datos[0].Fechaingreso;
    formData.RegistroProfesional = formData.RegistroProfesional
      ? formData.RegistroProfesional
      : datos[0].RegistroProfesional;
    formData.Nombre = formData.Nombre ? formData.Nombre : datos[0].Nombre;
    formData.Cedula = formData.Cedula ? formData.Cedula : datos[0].Cedula;
    formData.sexo = formData.sexo ? formData.sexo : datos[0].sexo;
    formData.estadocivil = formData.estadocivil
      ? formData.estadocivil
      : datos[0].estadocivil;
    formData.hijos = formData.hijos ? formData.hijos : datos[0].hijos;
    formData.Fechadenacimiento = formData.Fechadenacimiento
      ? formData.Fechadenacimiento
      : datos[0].Fechadenacimiento;
    formData.antpol = formData.antpol ? formData.antpol : datos[0].antpol;
    formData.Telefono = formData.Telefono
      ? formData.Telefono
      : datos[0].Telefono;
    formData.departamento = formData.departamento
      ? formData.departamento
      : datos[0].departamento;
    formData.Ciudad = formData.Ciudad ? formData.Ciudad : datos[0].Ciudad;
    formData.barrio = formData.barrio ? formData.barrio : datos[0].barrio;
    formData.Direccion = formData.Direccion
      ? formData.Direccion
      : datos[0].direccion;
    formData.imagen = formData.imagen ? formData.imagen : datos[0].imagen;
    onReturnValue(formData);
  };

  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Editar Cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={(e) => {
                    onSubmitUsuario(e);
                  }}
                  id="userform"
                >
                  <div className="row">
                    <div className="col">
                      <label className="col-form-label">
                        <h3>Cuenta</h3>
                      </label>

                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Foto <span className="text-danger">Maximo 1mb</span>
                        </label>

                        <input
                          accept="image/*"
                          type="file"
                          style={{ display: "none" }}
                          ref={referencia}
                          onChange={(e) => {
                            const file = e.target.files[0];

                            if (
                              file &&
                              file.type.substring(0, 5) === "image" &&
                              e.target.files[0].size < 1 * 1000 * 1024
                            ) {
                              setImage(file);
                            } else {
                              setImage(null);
                            }
                            setFormData({
                              imgvalue: file,
                            });
                          }}
                        ></input>
                        <div
                          className="container mt-3"
                          style={{ display: "flex" }}
                        >
                          {image ? (
                            <div className="card">
                              {" "}
                              <img
                                src={preview}
                                onClick={uploadFiles}
                                className="card-img-top"
                                style={{
                                  height: "350px",
                                  width: "300px",
                                  cursor: "pointer",
                                }}
                              ></img>
                            </div>
                          ) : (
                            <UploadAnimation
                              uploadFiles={uploadFiles}
                            ></UploadAnimation>
                          )}
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Usuario
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Usuario"
                            defaultValue={datos[0].Usuario}
                            name="usuario"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Usuario: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    

                      <div className="form-group mb-2  row ">
                        <label className="form-label">
                          Cargo
                          <span className="text-danger">*</span>
                        </label>
                        <div className=" mb-2">
                          <select
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                tipousuario: e.target.value,
                              });
                            }}
                            className="form-control"
                            name="tipousuario"
                            defaultValue={datos[0].tipousuario}
                          >
                            <option value="2">Enfermeria</option>
                            <option value="3">Logistica</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-2  row ">
                        <label className="form-label">
                          Profesion
                          <span className="text-danger">*</span>
                        </label>
                        <div className=" mb-2">
                          <select
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                subtipo: e.target.value,
                              });
                            }}
                            className="form-control"
                            name="Subtipo"
                            defaultValue={datos[0].subtipo}
                          >
                            <option value="Licenciado">Licenciado</option>
                            <option value="Tecnico">Tecnico</option>
                            <option value="Cuidador">Cuidador</option>
                            <option value="Fisioterapsita">
                              Fisioterapsita
                            </option>
                            <option value="Auxiliar">Auxiliar</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Fecha de Ingreso
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            defaultValue={datos[0].Fechaingreso}
                            name="Fechadeingreso"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Fechaingreso: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label className="col-form-label">
                        <h3>Datos Personales</h3>
                      </label>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Registro Profesional
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].RegistroProfesional}
                            placeholder="Registro Profesional"
                            name="RegistroProfesional"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                RegistroProfesional: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Nombre Completo
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].Nombre}
                            placeholder="Nombre"
                            name="nombre"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Nombre: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          CI
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="number"
                            className="form-control"
                            defaultValue={datos[0].Cedula}
                            placeholder="CI"
                            name="cedula"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Cedula: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2  row ">
                        <label className="form-label">Sexo</label>
                        <div className="">
                          <select
                            className="form-control"
                            name="sexo"
                            defaultValue={datos[0].sexo}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                sexo: e.target.value,
                              });
                            }}
                          >
                            <option value="Hombre">Hombre</option>
                            <option value="Mujer">Mujer</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-2  row ">
                        <label className="form-label">Estado Civil</label>
                        <div className="">
                          <select
                            className="form-control"
                            name="estadocivil"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                estadocivil: e.target.value,
                              });
                            }}
                            defaultValue={datos[0].estadocivil}
                          >
                            <option value="soltero/a">Soltero/a</option>
                            <option value="casado/a">Casado/a</option>
                            <option value="Divorciado/a">Divorciado/a</option>
                            <option value="Viudo/a">Viudo/a</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Hijos
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="number"
                            className="form-control"
                            defaultValue={datos[0].hijos}
                            placeholder="Hijos"
                            name="hijos"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                hijos: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Fecha de Nacimiento
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            defaultValue={datos[0].Fechadenacimiento}
                            name="Fechadenacimiento"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Fechadenacimiento: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Ant. Policial
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].antpol}
                            placeholder="Ant. Policial"
                            name="antpol"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                antpol: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label className="col-form-label">
                        <h3> Contacto</h3>
                      </label>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Telefono
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="number"
                            className="form-control"
                            defaultValue={datos[0].Telefono}
                            placeholder="Telefono"
                            name="telefono"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Telefono: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Departamento
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].departamento}
                            placeholder="Departamento"
                            name="departamento"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                departamento: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Ciudad
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].Ciudad}
                            placeholder="Ciudad"
                            name="ciudad"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Ciudad: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                          Barrio
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].barrio}
                            placeholder="Barrio"
                            name="barrio"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                barrio: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">Direccion</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={datos[0].direccion}
                            placeholder="Direccion"
                            name="direccion"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                Direccion: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="userform">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPreviewUsuario;
