import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
const ModalHoraExtra = (props) => {
  const {
    estado,
    onCloseModal,
    item,
    onReturnValue,
  } = props;
  const [IDLEGAJO,setidlegajo]=useState(item.IdLegajoEnfermeria);
  const [obs,setobs]=useState(item.Observacion);
   const handleSubmit2 = (event) => {
    let formdata= ({IDLEGAJO,obs})
    event.preventDefault();
    onReturnValue(formdata);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Obs.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
          
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Obs.
                <span className="text-danger">*</span>
              </label>
              <div className="">
                
                <textarea required className="form-control" value={obs} onChange={(e) => {
                    setobs(
                      e.target.value
                    );
                  }} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalHoraExtra;
