import { getAuthToken } from "../context/auth-context";
import { CALL_API } from "../context/constValues";

export async function getAllLegajo() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getAllLegajoActivos() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-activos`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getAllLegajoConfirmados() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-confirmados`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getAllLegajoByUsuario(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-usuario`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getAllLegajoByOneUsuario(IdUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/usuario/${IdUsuario}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoById(idLegajo) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/${idLegajo}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoByData(consultaObjeto) {
  const token = getAuthToken();
  const { fechaInicio, fechaFin, idUsuario, idPaciente } = consultaObjeto;
  console.log(consultaObjeto);
  let callUrl = `${CALL_API}/consultarLegajo?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;

  if (idUsuario && idUsuario !== "TODOS") {
    callUrl = `${callUrl}&idUsuario=${idUsuario}`;
  }

  if (idPaciente) {
    callUrl = `${callUrl}&idPaciente=${idPaciente}`;
  }

  const response = await fetch(callUrl, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoActivo() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/activeLegajo`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoByPaciente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-paciente/${idPaciente}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getLegajoActivoByPaciente(idPaciente) {
  console.log(idPaciente);
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/legajoActivo-paciente/${idPaciente}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getUltimosLegajosByCliente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/${idPaciente}/ultimos`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function insertLegajo(legajoObject) {
  const token = getAuthToken();
  const {
    idPaciente,
    fecha,
    modalidad,
    horaEntrada,
    latitud,
    longitud,
    observacion,
    honorarioenfermero,
    tarifahora,
    guardiahs,
    infosalida,
  } = legajoObject;

  const response = await fetch(`${CALL_API}/legajo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idPaciente: idPaciente,
      fecha: fecha,
      modalidad: modalidad,
      horaEntrada: horaEntrada,
      latitud: latitud,
      longitud: longitud,
      observacion: observacion,
      honorarioenfermero: honorarioenfermero,
      tarifahora: tarifahora,
      guardiahs: guardiahs,
      infosalida: infosalida,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function confirmSalidaLegajo(marcacionObject) {
  console.log(marcacionObject)
  const {
    fechaSalida,
    horaSalida,
    horascalculadas,
    totalhonorarioenfermero,
    totaltarifa,
    IdLegajoEnfermeria,
    latsalida,
    longsalida,
  } = marcacionObject;

  
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/confirm-legajo/${IdLegajoEnfermeria}`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fechaSalida: fechaSalida,
        horaSalida: horaSalida,
        horascalculadas: horascalculadas,
        totalhonorarioenfermero: totalhonorarioenfermero,
        totaltarifa: totaltarifa,
        latsalida: latsalida,
        longsalida: longsalida,
      }),
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isConfirmed;
}
export async function LegajoHoraExtra(marcacionObject) {
  console.log(marcacionObject)
  const { horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo } =
    marcacionObject;
    console.log(marcacionObject)
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-horaextra`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      horaextra: horaextra,
      cantidadhoraextra: cantidadhoraextra,
      IdLegajoEnfermeria: IdLegajoEnfermeria,
      horaextramaximo: horaextramaximo,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}
export async function deleteLegajo(idLegajo) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/${idLegajo}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return { ...data };
}
export async function GenerarLiquidacion(objeto) {
  const token = getAuthToken();
  const { idp, Valor } = objeto;
  const response = await fetch(
    `${CALL_API}/GenerarLiquidacion?IdPaciente=${idp}&Fechafinal=${Valor}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function GuardarLiquidacion(legajoObject) {
  console.log(legajoObject);
  const token = getAuthToken();
  const { totalHoras, totalTarifa, fechainicio, fechafin, IDPaciente } =
    legajoObject;
  const response = await fetch(`${CALL_API}/GuardarLiquidacion`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sumahoras: totalHoras,
      sumtarifa: totalTarifa,
      fechainicio: fechainicio,
      fechafin: fechafin,
      IdPaciente: IDPaciente,
    }),
  });
  const data = await response.json();
  console.log(data);
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }
  return data.result[0];
}
export async function MarcarLegajoLiquidacion(Objeto) {
  const { IDliquidacion, IDPaciente, fechafin } = Objeto;

  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/MarcarLegajoLiquidacion`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      IDliquidacion: IDliquidacion,
      IDPaciente: IDPaciente,
      fechafin: fechafin,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function GetliquidacionPaciente(IdPacientes) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/LiquidacionPaciente/${IdPacientes}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }
  return data.result;
}
// Editar Legajo para Liquidacion
export async function UpdateObs(objeto) {
  const {
    IDLEGAJO,
    obs
  } = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updateobs`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idLegajo: IDLEGAJO,
      obs:obs
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function EditLegajo(objeto) {
  const {
    idLegajo,
    fechaInicio,
    fechafin,
    horainicio,
    horafin,
    totalhs,
    totaltarifa,
  } = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updatelegajo`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idLegajo: idLegajo,
      fechaInicio: fechaInicio,
      fechafin: fechafin,
      horainicio: horainicio,
      horafin: horafin,
      totalhs: totalhs,
      totaltarifa: totaltarifa,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function AddLegajo(objeto) {
  const {
    idPaciente,
    idUsuario,
    paciente,
    fechaInicio,
    fechafin,
    horainicio,
    horafin,
    totalhs,
    totaltarifa,
    usuario,
    tipo,
    tarifa
  } = objeto;
  let entrydate = new Date(tipo);
  entrydate.setMinutes(entrydate.getMinutes() + entrydate.getTimezoneOffset());
  let findate = new Date(fechafin);
  findate.setMinutes(findate.getMinutes() + findate.getTimezoneOffset());
  console.log(entrydate);
  console.log(findate);
  let res;
  if (entrydate > findate) {
    res = "SI";
  } else {
    res = "NO";
  }
  console.log(res);
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/AddLegajo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idPaciente: idPaciente,
      idUsuario: idUsuario,
      paciente: paciente,
      fechaInicio: fechaInicio,
      fechafin: fechafin,
      horainicio: horainicio,
      horafin: horafin,
      totalhs: totalhs,
      totaltarifa: totaltarifa,
      usuario: usuario,
      tipo: res,
      tarifa
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function getliquidacionbyid(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/getliquidacion/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getlegajosbyliquidacion(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajobyliquidacion/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function liquidarliquidacion(objeto) {
  const { idliquidacion, facturanumero,montoliquidado,obs} = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/liquidarliquidacion`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idliquidacion: idliquidacion,
      numerofactura: facturanumero,
      montoliquidado:montoliquidado,
      obs:obs
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isConfirmed;
}
export async function getAllLiquidaciones() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/liquidaciones`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function InsertRecibo(Objeto) {
  console.log(Objeto)
  const token = getAuthToken();
  const {
    nombrepaciente,nombreusuario,Honorario,iduser,id
  } = Objeto

  const response = await fetch(`${CALL_API}/recibo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nombrepaciente:nombrepaciente,
      nombreusuario: nombreusuario,
      Honorario:Honorario,
      idusuario:iduser,
      idliquidacion:id
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}
export async function getidrecibo(idusuario,idrecibo) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/buscar/recibo?idusuario=${idusuario}&idrecibo=${idrecibo}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}