import React, { useState, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import Swal from "sweetalert2";

// image
//import logo from "../../images/logo-full.png";
import BgImage from "../../images/bg.png";
//import BgFull from "../../images/logo-full.png";
import logo from "../../images/logo-full.png";
import logolight from "../../images/logo-full-light.png";
import pol from "../../images/pol.jpg";
import {
  getUserCredentials,
  getUsuarioById,
  login,
} from "../../api/usuarioApi";
import AuthContext, { getTokenDuration } from "../../context/auth-context";
//import { url } from 'inspector';

function Login(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const authCtx = useContext(AuthContext);

  const dispatch = useDispatch();

  const onLogin = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Ingresando",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    
      const response = await login({ usuario: email, password: password });
      if (response.token) {
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);
        authCtx.login(response.token, expiration);
        const id = await getUserCredentials();
        const datauser = await getUsuarioById(id.IdUsuario);
        const res = await fetch(
          `https://eethsa.xyz/eethsa1/${datauser[0].imagen}`
        );
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        authCtx.userdata(datauser[0].Nombre, imageObjectURL);
        dispatch(loadingToggleAction(true));
        dispatch(loginAction("demo@example.com", "123456", props.history));
        Swal.close();
      }
     

   
  };

  const element = document.querySelector("body");
  let dataTheme = element.getAttribute("data-theme-version");

  return (
    <div className="container h-100">
      <div className="row h-100 align-items-center justify-contain-center">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="row m-0">
                <div
                  className="col-xl-6 col-md-6 sign text-center sign-bg"
                  style={{ backgroundImage: "url(" + pol + ")" }}
                >
                  <div>
                    <div className="text-center my-5">
                      <Link to={"#"}>
                        {/* <img src={BgFull} alt="" /> */}
                        <img
                          className="logo-abbr dark-logo"
                          width="200"
                          src={logo}
                          alt=""
                        />
                        <img
                          className="logo-abbr light-logo text-center m-auto"
                          width="200"
                          src={logolight}
                          alt=""
                        />
                      </Link>
                    </div>
                    {dataTheme === "light" ? (
                      <img
                        src={BgImage}
                        style={{ width: 300 }}
                        className=" slideskew img-fix bitcoin-img sd-shape7"
                      ></img>
                    ) : (
                      <img
                        src={BgImage}
                        className=" slideskew img-fix bitcoin-img sd-shape7"
                      ></img>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="sign-in-your px-2">
                    <h4 className="fs-20 ">Ingrese con su Cuenta</h4>
                    <span>
                      Bienvenido! loguea con los datos proporcionados{" "}
                    </span>

                
                    
                    <form onSubmit={onLogin}>
                      <div className="mb-3">
                        <label className="mb-1">
                          <strong>Cuenta</strong>
                        </label>
                        <input
                        required
                          type="text"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Tu cuenta"
                        />

                      
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">
                          <strong>Contraseña</strong>
                        </label>
                        <input
                        required
                          type="password"
                          className="form-control"
                          placeholder="Tu contraseña"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      
                      </div>
                      <div className="row d-flex justify-content-between mt-4 mb-2">
                        <div className="mb-3">
                          <div className="form-check custom-checkbox ms-1">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Recuerdame
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Ingresar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
