import Lottie from "react-lottie"
import Userprofile from "../../../../Static/Userprofile.json"
const UserprofileData ={loop:true,autoplay:true,animationData:Userprofile}
export const UploadAnimation=({uploadFiles})=>{

return(
    <div onClick={uploadFiles} style={{background:'#01a3ff'}}>
        <Lottie options={UserprofileData} height={350} width={300}></Lottie>
    </div>
)

}