import {  useEffect,useState } from "react";
import { Button, Modal } from "react-bootstrap";


 

const ModalAsignarUsuarioEdit = (props) => {
  const { estado, onCloseModal, dataedit,onReturnValue} = props;
  const [formData3, setValue] = useState(dataedit);
  const handleSubmit2 = (event) => {
    event.preventDefault();
    onReturnValue(formData3);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Usuario Asignado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <h3>{dataedit.NombrePaciente}</h3>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Honorario por Hora
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={formData3.honorarioenfermero}
                  onChange={(e) => {
                    setValue({ ...formData3, honorarioenfermero: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Guardia hs.
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={formData3.guardiahs}
                  onChange={(e) => {
                    setValue({ ...formData3, guardiahs: e.target.value });
                  }}
                />
              </div>
            </div>
            <label className="col-form-label">
              Modalidad
              <span className="text-danger">*</span>
            </label>
            <select className="form-control" value={formData3.Modalidad}  onChange={(e) => {
                    setValue({ ...formData3, Modalidad: e.target.value });
                  }}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAsignarUsuarioEdit;
