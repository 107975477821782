import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BaseSelect from "react-select";
import FixRequiredSelect from "./FixRequiredSelect";
import { getAllUsuario } from "../../../../api/usuarioApi";
const options = [
  { value: 1, label: "1 - One" },
  { value: 2, label: "2 - Two" },
  { value: 3, label: "3 - Three" },
];
const Select = (props) => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);

const ModalAsignarUsuario = (props) => {
  const {
    estado,
    onCloseModal,
    onReturnValue,
    idpaciente
  } = props;

  const [listUsuarios, setListUsuario] = useState([]);
  const [formData3, setValue] = useState([]);
  const assigmentValue = useCallback(async () => {
    const resultUsuario = await getAllUsuario();
    let filterNumbers = resultUsuario.filter(num => num.estaActivo!=='Inactivo');
    const resultMapeado = filterNumbers.map((item) => {
      {
        return { label: item.Nombre + " " + item.subtipo, value: item.IdUsuario };
      }
    });
    setListUsuario(resultMapeado);
    setValue({ ...formData3, tarifahora:  idpaciente[0].tarifa});
  }, []);

  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);

  const handleSubmit2 = (event) => {
    event.preventDefault();
    formData3.modalidad = formData3.modalidad
      ? formData3.modalidad : "1";
    onReturnValue(formData3);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Usuario
                <span className="text-danger">*</span>
              </label>
              <Select
                options={listUsuarios}
                isSearchable
                required
                onChange={(e) => {
                  setValue({
                    ...formData3,
                    usuario: e.value,
                    usuarioAsignado: e.label,
                  });
                }}
              />
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Honorario por Hora
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, honorariohora: e.target.value });
                  }}
                />
              </div>
            </div>
           
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Guardia hs.
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, guardiahs: e.target.value });
                  }}
                />
              </div>
            </div>
            <label className="col-form-label">
              Modalidad
              <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              onChange={(e) => {
                setValue({ ...formData3, modalidad: e.target.value });
              }}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAsignarUsuario;
