import React, { useCallback, useEffect, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Table, Button } from "react-bootstrap";
import ModalFeriados from "../components/sis/modalopciones/ModalFeriados";
import { getAllFeriados, insertFeriado,updateFeriado,deleteFeriado} from "../../api/CalendarioFeriado";
import Swal from "sweetalert2";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};
export const Opciones = () => {
  const [Feriados, setFeriados] = useState([]);
  const [isnew, setisnew] = useState(true);
  const [datoferiado, setdatoferiado] = useState([]);
  useEffect(() => {
    assigmentValue();
  }, []);
  const assigmentValue = useCallback(async () => {
    try {
      const result = await getAllFeriados();
      const dato = {
        fecha: "",
        nombrefecha: "",
         idcalendarioferiado:""
      };
      setdatoferiado(dato);
      setFeriados([...result]);
    } catch (err) {
      console.log(err);
    }
  });
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    typeModal: null,
    onReturnValue: null,
  });
  const handleShow = () => {
    const dato = {
      fecha: "",
      nombrefecha: "",
      idcalendarioferiado:""
    };
    setdatoferiado(dato);
    setisnew(true);
    dispatchReducer({ type: "SHOW", typeModal: "CLIENTE" });
  };
  const handleShowEdit = (data) => {
    setdatoferiado(data);
    setisnew(false);
    dispatchReducer({ type: "SHOW", typeModal: "CLIENTE" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const onReturnDataCliente = async (data, isnew) => {
    dispatchReducer({ type: "END" });
    Swal.fire({
      title: "Guardando",
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
    try {
      let result;
      if (isnew == true) {
        result = await insertFeriado(data);
      } else {
        result = await updateFeriado(data);
      }

      if (result) {
        if (result) {
          Swal.fire({
            title: "Feriado ",
            html: `Se modifico correctamente`,
            icon: "success",
          });
          assigmentValue();
          Swal.close();
        }
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const deleteitem = (item) => {
    Swal.fire({
      title: "Eliminar Fecha",
      text: `Estas seguro que desea eliminar la Fecha ${item.fechanombre}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminando",
          didOpen: () => {
            Swal.showLoading();
          }
        });
        deleteFeriado(item.idcalendarioferiado)
        const list = Feriados.filter(row => row.idcalendarioferiado !== item.idcalendarioferiado); //only remove the selected row
        setFeriados(list)
        Swal.close();
     
      }
     
    });
   
  };
  return (
    <div className="col-xl-6 col-xxl-8 col-lg-8">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="card-title">Feriados</h4>
          <Button variant="primary" size="sm" onClick={handleShow}>
            Agregar
          </Button>
          {reducer.isShow && (
            <ModalFeriados
              estado={reducer.isShow}
              typeModal={reducer.typeModal}
              onCloseModal={onCloseModal}
              onReturnValue={onReturnDataCliente}
              item={datoferiado}
              isnew={isnew}
            />
          )}
        </div>
        <div className="card-body">
          <PerfectScrollbar
            style={{ height: "370px" }}
            id="DZ_W_TimeLine"
            className="widget-timeline dlab-scroll height370 ps ps--active-y"
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="width80">
                    <strong>#</strong>
                  </th>
                  <th>
                    <strong>Fecha</strong>
                  </th>
                  <th>
                    <strong>Nombre</strong>
                  </th>
                  <th>
                    <strong>Opciones</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Feriados.slice().map((item, index2) => {
                  return (
                    <tr key={index2}>
                      <td>{item.idcalendarioferiado}</td>
                      <td>{item.fecha}</td>
                      <td>{item.fechanombre}</td>
                      <td>
                        <div className="d-flex">
                          <li
                            className="btn btn-primary shadow btn-xs sharp me-1"
                            onClick={() => handleShowEdit(item)}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </li>
                          <li
                            className="btn btn-danger shadow btn-xs sharp"
                            onClick={() => deleteitem(item)}
                          >
                            <i className="fa fa-trash"></i>
                          </li>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};
export default Opciones;
