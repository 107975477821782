
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import Headerimg from '../../../../utils/header.jpeg'
import Footerimg from '../../../../utils/footer.jpeg'




export default function genPDF(props) 
  {
    const {datos} = props;
    var doc = new jsPDF();
    var totalPagesExp = "{total_pages_count_string}";
    var margin = 20
    var margin2 = 72
    console.log(datos)
    doc.autoTable({
      didDrawPage: function(data) {
        // datos personales
        doc.setTextColor(40);
        doc.setFontSize(18);
        doc.text("Datos Personales",margin, 77, null, null, );
        
        doc.setFontSize(13);
        doc.text(`Nombre`, margin, 85, null, null,)
        doc.text(`Fecha de Nacimiento`, margin, 85+5, null, null,)
        doc.text(`CI`, margin, 85+10, null, null,)
        doc.text(`Telefono`,margin, 85+15, null, null,)
        doc.text(`Correo`, margin, 85+20, null, null,)
        doc.text(`Ciudad`, margin, 85+25, null, null,)
        doc.text(`Direccion`,margin, 85+30, null, null,)
       

        doc.text(`Peso`,margin, 125+5 , null, null,);
        doc.text(`Altura`,margin, 125+10 , null, null,);

        doc.text(`Sexo`,margin, 125+15 , null, null,);

        doc.text(`Alergias a Medicamentos`,margin, 125+20, null, null,);

        doc.text(`Alergias en Gral`,margin, 125+25 , null, null,);

        doc.text(`Diagnostico Medico`,margin, 125+30 , null, null,);

        doc.text(`Movilidada Propia`,margin, 125+35 , null, null,);
        doc.text(`Equipo de Rehabilitacion`,margin, 125+40 , null, null,);
        doc.text(`Via de Alimentacion`,margin, 125+45 , null, null,);

        doc.text(`Forma de Alimentacion`,margin, 125+50, null, null,);
        doc.text(`tiempo de guardia`, margin, 125+55, null, null,);
        doc.text(`Observacion Medica`, margin, 125+60, null, null,);




        doc.setTextColor(170,170,170);


        doc.text(`:${datos.NombrePaciente}`, margin2, 85, null, null, );
        doc.text(`:${datos.FechaNacimiento}`, margin2, 85+5, null, null, );
        doc.text(`:${datos.Cedula}`, margin2, 85+10, null, null, );
        doc.text(`:${datos.Celular}`, margin2, 85+15, null, null, );
        doc.text(`:${datos.Correo===null? "" : datos.Correo }`, margin2, 85+20, null, null,);
        doc.text(`:${datos.Ciudad}`, margin2, 85+25, null, null, );
        doc.text(`:${datos.Direccion}`, margin2, 85+30, null, null,);
        // datos medicos
        doc.setTextColor(40);
        doc.setFontSize(18);
        doc.text("Datos Medicos",margin, 123, null, null,);
        doc.setTextColor(170,170,170);
        doc.setFontSize(13);
        doc.text(`:${datos.peso}`,margin2, 125+5 , null, null, );
        doc.text(`:${datos.altura}`,margin2, 125+10, null, null, );
        doc.text(`:${datos.Sexo}`,margin2, 125+15, null, null, );
        doc.text(`:${datos.alergiasamedicamentos}`,margin2, 125+20, null, null, );
        doc.text(`:${datos.alergiasgral? datos.alergiasgral:""}`,margin2,125+25, null, null, );
        doc.text(`:${datos.diagnosticoMedico? datos.diagnosticoMedico:""}`,margin2, 125+30, null, null, );
        doc.text(`:${datos.movilidadPropia}`,margin2, 125+35, null, null, );
        doc.text(`:${datos.equipoRehabilitacion}`,margin2, 125+40, null, null, );
        doc.text(`:${datos.formaAlimentacion}`,margin2, 125+45, null, null, );
        doc.text(`:${datos.viadealimentacion}`,margin2, 125+50, null, null, );
        doc.text(`:${datos.Guardiahs? datos.Guardiahs:""}`,margin2, 125+55, null, null, );
        doc.text(`:${datos.obs2? datos.obs2:""}`,margin2, 125+60, null, null, );
        // Encargado & Facturacion
      
        
        doc.setTextColor(40);

        // Header 
        // Footer
        doc.setFontSize(20);
        doc.addImage(`${Headerimg}`, 'JPEG', 0, 0, 210,40);
        doc.addImage(`${Footerimg}`, 'JPEG', 0, 255, 210,40)
        doc.text("Ficha Usuario del Servicio EETHSA", data.settings.margin.left+40 , 65);
        doc.setFontSize(10);
        var str = "Page " + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left+5+5, pageHeight - 10);
      },
      margin: {
        top: 30
      }
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    // doc.save(`${datos.NombrePaciente}-${datos.Cedula}.pdf`);
    doc.output('dataurlnewwindow');
  }

