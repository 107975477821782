import {  useState } from "react";
import { Button, Modal} from "react-bootstrap";
import Swal from "sweetalert2";
const Modalpass = (props) => {
  const { estado, onCloseModal, datos, onReturnValue } = props;
  
 

 

  const [formData, setFormData] = useState([]);
  const onSubmitUsuario = (e) => {
    e.preventDefault();

    if (formData.contraseña1!=formData.contraseña2)
    {
        Swal.fire({
            title: "Contraseñas no coinciden",
            icon:"error",
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: true,
          });
    }else
    {
        onReturnValue(formData)
    }
   
  };

  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="s">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={(e) => {
                    onSubmitUsuario(e);
                  }}
                  id="userform"
                >
                  <div className="form-group mb-2 row">
                        <label className="col-form-label">
                        Contraseña
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                          required
                            type="password"
                            className="form-control"
                            placeholder="Contraseña"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                contraseña1: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-form-label">
                       Repetir Contraseña
                          <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                          required
                            type="password"
                            className="form-control"
                            placeholder="Repetir Contraseña"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                contraseña2: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="userform">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modalpass
