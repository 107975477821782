import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef,
  } from "react";
  import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
  } from "react-table";
  import { Link, useHistory } from "react-router-dom";
  
  import { COLUMNS_LIQ } from "./Columns";
  import { GlobalFilter } from "./GlobalFilter";
  //import './table.css';
  import "./filtering.css";
  import {
    getAllLiquidaciones,
  } from "../../api/marcacionApi";
  import {getUserCredentials}  from "../../api/usuarioApi" 
  import { TYPE_USER } from "../../utils/Const";

  

  export const Liquidaciones  = () => {
    const columns = useMemo(() => COLUMNS_LIQ, []);
    const [data, setdata] = useState([]);
    const history = useHistory();
    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 10, pageIndex: 0 },
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state,
      page,
      gotoPage,
      pageCount,
      pageOptions,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      setGlobalFilter,
    } = tableInstance;
  
    const { globalFilter, pageIndex } = state;
  
    const assigmentValue = useCallback(async () => {
      try {
      
        const resultUser = await getUserCredentials();
        if (resultUser.IdTipoUsuario !== TYPE_USER.ADMINISTRACION.Id)
          history.push("/");
        const result = await getAllLiquidaciones();
        setdata([...result]);
        
      } catch (err) {
       
        console.log(err);
      }
    });
  
    useEffect(() => {
      assigmentValue();
    }, []);
    const tableRef = useRef(null);
  
   
    return (
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Liquidaciones</h4>
    
        </div>
  
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              className="table dataTable display"
              ref={tableRef}
            >
              <thead>
                {headerGroups.map((headerGroup,index1) => (
                  <tr {...headerGroup.getHeaderGroupProps()}key={index1}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {column.canFilter ? column.render("Filter") : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} >
                {page.map((row,index2) => {
                  prepareRow(row);
  
                  return (
                    <tr {...row.getRowProps()}key={index2}  >
                      {row.cells.map((cell,index3) => {
                        
                        return (
                          <td  {...cell.getCellProps()} key={index3}   style={{
                            padding: '10px',
                           
                        }} 
                        
                        >
                            <Link
                              to={{
                                pathname: "/fichaliquidacion",
                                state: { id: row.values.id },
                              }}
                            >
                              {" "}
                              {cell.render("Cell")}{" "}
                            </Link>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>
  
                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default Liquidaciones;