
import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import BaseSelect from "react-select";
import FixRequiredSelect from "./FixRequiredSelect";
import { getAllUsuario } from "../../../../api/usuarioApi";
const options = [
    { value: 1, label: "1 - One" },
    { value: 2, label: "2 - Two" },
    { value: 3, label: "3 - Three" },
];
const Select = (props) => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options || options}
    />
);
const ModalDescargaSesiones = (props) => {

    const [isLoading, setisLoading] = useState(true);
    const { estado, onCloseModal,  onReturnValue , ClienteOCuenta,Lista} = props;
    const [listUsuarios, setListUsuario] = useState([]);
    const assigmentValue = useCallback(async () => {
        setListUsuario(Lista)
        setisLoading(false)
    }, []);
    useEffect(() => {
        assigmentValue();
    }, [assigmentValue]);

    const [formData, setFormData] = useState([]);
    const onSubmitUsuario = (e) => {
        e.preventDefault();
        if (formData.ID=='TODOS')
        {
            formData.ID=undefined
        }
        onReturnValue(formData)

    };

    return (
        <>
            <Modal show={estado} onHide={onCloseModal} size="s">
                <Modal.Header closeButton>
                    <Modal.Title>Generar Reporte de Marcaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-validation">
                                <form
                                    className="form-valide"
                                    action="#"
                                    method="post"
                                    onSubmit={(e) => {
                                        onSubmitUsuario(e);
                                    }}
                                    id="userform"
                                >
                                    <div className="form-group mb-2 row">
                                        <label className="col-form-label">
                                            {ClienteOCuenta}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                            <Select
                                                options={listUsuarios}
                                                isLoading={isLoading}
                                                isSearchable
                                                required
                                                noOptionsMessage={() => "Sin Opciones"}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        ID: e.value,
                                                      });
                          
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-form-label">
                                            Fecha Incial
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                            <input
                                                required
                                                type="date"
                                                className="form-control"
                                                placeholder="Repetir Contraseña"
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        fechaInicio: e.target.value,
                                                      });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-form-label">
                                            Fecha Final
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                            <input
                                                required
                                                type="date"
                                                className="form-control"
                                                placeholder="Repetir Contraseña"
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        fechaFin: e.target.value,
                                                      });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button type="submit" form="userform">
                        Generar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalDescargaSesiones
