import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import Swal from "sweetalert2";
// BS
/// Scroll

import Button from "react-bootstrap/Button";
import Modale from "../components/sis/Modale";
import { REACT_APP_LINKMAP } from "../../utils/Const";
import {
  confirmSalidaLegajo,
  getAllLegajoByUsuario,
  getLegajoActivo,
  getLegajoActivoByPaciente,
  insertLegajo,
  LegajoHoraExtra,
} from "../../api/marcacionApi";
import { ThreeDots } from "react-loader-spinner";
import { getUserCredentials } from "../../api/usuarioApi";
import { getAsignacionesByUserAndClient } from "../../api/profesionalPacienteApi";
// Page titie
import moment from "moment";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
        legajoObject: state.legajoObject,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};

const Marcacion = () => {
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    legajoObject: null,
    onReturnValue: null,
    typeModal: null,
    x: 0,
  });
  const [listMarcacion, setListMarcacion] = useState([]);
  const [InfoSalida, setInfoSalida] = useState("");
  const [guardiaHS, setGuardiaHs] = useState("");
  const [Honorario, setHonorario] = useState("");
  const [Legajo, setLegajo] = useState("");
  const [Loading, setLoading] = useState(true);
  const [mostrarEntrada, setMostrarEntrada] = useState(true);

  const onLoadValuesMarcacion = useCallback(async () => {
    try {
      const listResult = await getAllLegajoByUsuario();
      setListMarcacion([listMarcacion, ...listResult]);
    } catch (err) {
      console.log(err);
    }
  });

  const onObtainMarcacion = useCallback(async () => {
    // se necesita un load
    setLoading(true);
    const legajoObject = await getLegajoActivo();
    const currentData = legajoObject[0];
    setLegajo(legajoObject[0]);
    if (legajoObject[0]) {
      setInfoSalida("Activo");
      setHonorario(legajoObject[0].Paciente);
      setGuardiaHs(legajoObject[0].TotalHoraMaximo);
    } else {
      setInfoSalida("");
      setHonorario("");
      setGuardiaHs("");
    }
    currentData ? setMostrarEntrada(false) : setMostrarEntrada(true);
    setLoading(false);
  });

  useEffect(() => {
    onLoadValuesMarcacion();
    onObtainMarcacion();
  }, []);
  const CalculateHours = (fechaBegin, fechaEnd, HoraEntrada, HoraSalida) => {
    var fecha1 = new Date(fechaBegin+" "+HoraEntrada);
    var fecha2 =new Date(fechaEnd +" "+HoraSalida);
    
    let diff =(fecha2 - fecha1)/3600000;
    let num = Number(diff.toFixed(2))
    return {
      canthoras: num,
    };
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      min = "" + d.getMinutes(),
      sec = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return ` ${hour}:${min}:${sec} del ${day}-${month}-${year} `;
  }
  function addHoursToDate(objDate, intHours) {
    var d0 = objDate.toString();
    var d1 = new Date(Date.parse(d0));
    var numberOfMlSeconds = d1.getTime();
    var addMlSeconds = intHours * 60 * 60000;
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

    return formatDate(newDateObj);
  }

  const onEntradaMarcacion = async (dataReturn) => {
    dispatchReducer({ type: "END" });
    try {
      Swal.fire({
        title: "Por Favor Espere..",
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      let { latitude, longitude, IdPacientes } = dataReturn;
      const LegajoActivo = await getLegajoActivoByPaciente(IdPacientes);
      const usercredentials = await getUserCredentials();
      const asignacion = await getAsignacionesByUserAndClient(
        IdPacientes,
        usercredentials.IdUsuario
      );
      if (LegajoActivo[0] == undefined) {
        const result = await fetch(
          `${REACT_APP_LINKMAP}=${latitude}&long=${longitude}`
        );
        const resultJson = await result.json();
        const resultInsert = await insertLegajo({
          idPaciente: IdPacientes,
          fecha: resultJson.date,
          modalidad: asignacion[0].Modalidad,
          horaEntrada: resultJson.time_24,
          latitud: latitude,
          longitud: longitude,
          observacion: "NADA",
          honorarioenfermero: asignacion[0].honorarioenfermero,
          tarifahora: asignacion[0].tarifahora,
          guardiahs: asignacion[0].guardiahs,
          infosalida: "",
        });
        if (resultInsert) {
          Swal.close();
          Swal.fire({
            title: "Marcado Completo",
            html: `Fecha: ${resultJson.date} <br> Hora: ${resultJson.time_24} `,
            icon: "success",
          });
          onLoadValuesMarcacion();
          onObtainMarcacion();
        }
      } else if (LegajoActivo[0].Modalidad == asignacion[0].Modalidad) {
        const result = await fetch(
          `${REACT_APP_LINKMAP}=${latitude}&long=${longitude}`
        );
        const resultJson = await result.json();
        const NEWDATE = addHoursToDate(
          resultJson.date_time,
          asignacion[0].guardiahs
        );
        const resultInsert = await insertLegajo({
          idPaciente: IdPacientes,
          fecha: resultJson.date,
          modalidad: asignacion[0].Modalidad,
          horaEntrada: resultJson.time_24,
          latitud: latitude,
          longitud: longitude,
          observacion: "NADA",
          honorarioenfermero: asignacion[0].honorarioenfermero,
          tarifahora: asignacion[0].tarifahora,
          guardiahs: asignacion[0].guardiahs,
          infosalida: NEWDATE,
        });
        if (resultInsert) {
          Swal.close();
          Swal.fire({
            title: "Marcado Completo",
            html: `Fecha: ${resultJson.date} <br> Hora: ${resultJson.time_24} `,
            icon: "success",
          });
          onLoadValuesMarcacion();
          onObtainMarcacion();
        }
      } else {
        Swal.close();
        Swal.fire({
          title: "Error",
          html: `Usuario:<strong>${LegajoActivo[0].Usuario}</strong> , Debe Cerrar Sesion`,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
    }
  };
  const convertToMinutes = function (timeString) {
    var hms = timeString.split(":");
    return Math.ceil(parseInt(hms[1]) + parseInt(hms[0]) * 60);
  };
  const onSalidaMarcacion = async (dataReturn) => {
    dispatchReducer({ type: "END" });
    try {
      Swal.fire({
        title: "Por Favor Espere..",
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      let { latitude, longitude, IdLegajoEnfermeria } = dataReturn;
      const result = await fetch(
        `${REACT_APP_LINKMAP}=${latitude}&long=${longitude}`
      );
      const resultJson = await result.json();
      const horascalculadas = CalculateHours(
        Legajo.Fecha,
        resultJson.date,
        Legajo.HoraEntrada,
        resultJson.time_24
      );
      let hora;
      let honorariototal;
      let tarifatotal;
      if (
        horascalculadas.canthoras > Legajo.TotalHoraMaximo &&
        Legajo.TotalHoraMaximo != "24"
      )
       {
        hora = Legajo.TotalHoraMaximo;
        honorariototal = hora * Legajo.CostoHoraProfesional;
        tarifatotal = hora * Legajo.CostoHoraEmpresa;
      } else {
              hora = horascalculadas.canthoras;
              honorariototal = hora * Legajo.CostoHoraProfesional;
              tarifatotal = hora * Legajo.CostoHoraEmpresa;
            }
      const resultConfirm = await confirmSalidaLegajo({
        fechaSalida: resultJson.date,
        horaSalida: resultJson.time_24,
        horascalculadas: hora,
        totalhonorarioenfermero: honorariototal,
        totaltarifa: tarifatotal,
        IdLegajoEnfermeria: IdLegajoEnfermeria,
        latsalida: latitude,
        longsalida: longitude,
      });
      if (resultConfirm) {
        Swal.close();
        Swal.fire({
          title: "Salida Completa",
          html: `Fecha: ${resultJson.date} <br> Hora: ${resultJson.time_24} `,
          icon: "success",
        });
        setMostrarEntrada(true);
        onLoadValuesMarcacion();
        onObtainMarcacion();
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  const entradaHandler = () => {
    dispatchReducer({
      type: "SHOW",
      typeModal: "ENTRADA",
      onReturnValue: onEntradaMarcacion,
    });
  };
  const salidaHandler = async () => {
    const legajoObject = await getLegajoActivo();
    const currentData = legajoObject[0];
    dispatchReducer({
      type: "SHOW",
      typeModal: "SALIDA",
      onReturnValue: onSalidaMarcacion,
      legajoObject: currentData,
    });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };

  return (
    <Fragment>
      {/* <Ext /> */}

      <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-header">
              <h4 className="card-title">Marcar</h4>
            </div>
            <div className="card-body p-4">
              <ThreeDots
                visible={Loading}
                height="80"
                width="80"
                color="#01a3ff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              {!Loading && (
                <div className="media ai-icon">
                  {mostrarEntrada && (
                    <Button variant="primary" onClick={entradaHandler}>
                      Entrada
                    </Button>
                  )}

                  {!mostrarEntrada && (
                    <Button variant="primary" onClick={salidaHandler}>
                      Salida
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-6 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-header">
              <h4 className="card-title">Información de sesión</h4>
            </div>
            <div className="card-body p-4">
              <div className="media ai-icon">
                {mostrarEntrada && (
                  <div>Marque Entrada para Mostrar la información</div>
                )}

                {!mostrarEntrada && (
                  <div>
                    <span>
                      <strong>Sesion:</strong>
                      {InfoSalida}
                    </span>
                    <br></br>
                    <span>
                      <strong>Total de Horas de guardia: </strong>
                      {guardiaHS}
                    </span>
                    <br></br>
                    <span>
                      <strong>Usuario del Servicio: </strong>
                      {Honorario}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Sesiones</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive recentOrderTable">
              <table className="table verticle-middle table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">Fecha Entrada</th>
                    <th scope="col">Hora Entrada</th>
                    <th scope="col">ubicacion</th>
                    <th scope="col">Fecha Salida</th>
                    <th scope="col">Hora Salida</th>
                  </tr>
                </thead>
                <tbody>
                  {listMarcacion.slice(0, 11).map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.IdLegajoEnfermeria}</td>
                        <td>{item.Usuario}</td>
                        <td>{item.Fecha}</td>
                        <td>{item.HoraEntrada}</td>
                        <td>
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              item.Latitud +
                              "," +
                              item.Longitud
                            }
                            target="_blank"
                          >
                            <div>{item.Paciente}</div>
                          </a>
                        </td>
                        <td>{item.FechaSalida}</td>
                        <td>{item.HoraSalida}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {reducer.isShow && (
        <Modale
          estado={reducer.isShow}
          typeModal={reducer.typeModal}
          onCloseModal={onCloseModal}
          onReturnValue={reducer.onReturnValue}
          legajoObject={reducer.legajoObject}
        />
      )}
    </Fragment>
  );
};

export default Marcacion;
