
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import Headerimg from '../../../utils/header.jpeg'
import Footerimg from '../../../utils/footer.jpeg'

function horasalida(fecha, hora, horas) {
  let fechatotal = new Date(`${fecha} ${hora}`);
  const hoursToMs = fechatotal.getTime() + horas * 60 * 60 * 1000;

  fechatotal.setTime(hoursToMs);
  const showTime =
    addZeroBefore(fechatotal.getHours()) +
    ":" +
    addZeroBefore(fechatotal.getMinutes()) +
    ":" +
    addZeroBefore(fechatotal.getSeconds());

  return `${showTime}`;
}
function addZeroBefore(n) {
  return (n < 10 ? "0" : "") + n;
}



export default function genPDF2(props) 
  {
    var rows = [];
    var col = ["Fecha","Hora Entrada","Fecha Salida","Hora Salida","Horas","Honorario","Enfermero"];
    const {legajosprof,NombreUsuario} = props; 
    let datos = legajosprof
    const xdatox =  {"Fecha":"Total"}
    datos.push(xdatox)
    var doc = new jsPDF();
    var xOffset = doc.internal.pageSize.width / 2
    var totalPagesExp = "{total_pages_count_string}";
    doc.setFontSize(20);
    doc.text(`${NombreUsuario}`, xOffset, 50, null, null, "center");
    doc.setFontSize(15);
    doc.setTextColor(112, 112, 112);
    doc.text("Liquidacion", xOffset, 57, null, null, "center");
    doc.text(`${datos[0].Paciente}`, xOffset, 62, null, null, "center");
    let totalhoras=0
    let totalhonorario=0
       datos.forEach(element => {    
         let HoraSalida=   horasalida(
            element.Fecha,
            element.HoraEntrada,
            element.horascalculadas +
            element.horashoraextra
          )
            let HorasCalculadas = element.horascalculadas + element.horashoraextra
           let TarifaCalculadas = (element.CostoHoraProfesional * HorasCalculadas)
           let HoraEntrada = element.HoraEntrada +""
           if (element.Fecha==="Total")
            {
              HoraSalida=" "
              HorasCalculadas=Math.round(totalhoras).toLocaleString('es')
              TarifaCalculadas= Math.round(totalhonorario).toLocaleString('es')
              HoraEntrada=" "
            }
          var temp = [element.Fecha,HoraEntrada,element.FechaSalida,HoraSalida,HorasCalculadas,TarifaCalculadas.toLocaleString('es'),element.Usuario];
          totalhoras += HorasCalculadas
          totalhonorario +=TarifaCalculadas 
          rows.push(temp);
         
      });        

      doc.autoTable(col, rows, { startY: 65, showHead: 'firstPage',margin:{top: 60, right:  10, bottom: 80, left: 10}});
      datos.pop()


        // Footer
       
        doc.setFontSize(10);
        var str = "Page " + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        for (let pageNumber = 1; pageNumber <= doc.getNumberOfPages(); pageNumber++) {
          doc.setPage(pageNumber)
          doc.addImage(`${Headerimg}`, 'JPEG', 0, 0, 210,40);
           doc.addImage(`${Footerimg}`, 'JPEG', 0, 255, 210,40)
        }
   
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.output('dataurlnewwindow');
    //doc.save(`liquidacion.pdf`);
  }
