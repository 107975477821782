import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ModalCliente from "../components/sis/ModalCliente";
import ModalMarcaciones from "../components/sis/modalcliente/Vermarcaciones";
import ModalAsignarUsuario from "../components/sis/modalcliente/Asignarusuario";
import ModalAsignarUsuarioEdit from "../components/sis/modalcliente/Asignarusuarioedit";
import ModalPreviewCliente from "../components/sis/modalcliente/ModalPreviewCliente";
import { Table, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useCallback } from "react";
import { exportToExcel } from "react-json-to-excel";
import {
  getPacientesById,
  updatePaciente,
  EstadoPaciente,
} from "../../api/pacienteApi";
import { getAllUsuario } from "../../api/usuarioApi";
import { Dropdown } from "rsuite";
import PageIcon from "@rsuite/icons/Page";
import MenuIcon from "@rsuite/icons/Menu";
import CharacterAuthorizeIcon from "@rsuite/icons/CharacterAuthorize";
import CalendarIcon from "@rsuite/icons/Calendar";
import EditIcon from "@rsuite/icons/Edit";
import EventDetailIcon from "@rsuite/icons/EventDetail";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";

import { deletePaciente } from "../../api/pacienteApi";
import MinusRoundIcon from "@rsuite/icons/MinusRound";
import { getLegajoByData, getLegajoByPaciente } from "../../api/marcacionApi";
import {
  getAsignacionByPaciente,
  insertAsignacion,
  updateAsignacion,
  delteAsignacion,
} from "../../api/profesionalPacienteApi";
import ModalDescargaSesiones from "../components/sis/modalreportes/ModalDescargaSesiones";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "2":
      return {
        isShowAsignarUsuario: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "3":
      return {
        isShowAsignarUsuarioEdit: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "4":
      return {
        isShowDescargaMarcaciones: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "5":
      return {
        isShowModalPDF: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return {
        ...curShow,
        isShow: false,
        isShowAsignarUsuario: false,
        isShowAsignarUsuarioEdit: false,
        isShowDescargaMarcaciones: false,
        isShowModalPDF: false,
      };
    case "default":
  }
};

const FichaCliente = () => {
  const history = useHistory();
  const [defaultValue, setRow] = useState([]);
  const [Vermarciones, setVermarcaciones] = useState(false);

  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShowAsignarUsuario: false,
    isShowAsignarUsuarioEdit: false,
    isShowDescargaMarcaciones: false,
    isShowModalPDF: false,
    typeModal: null,
    onReturnValue: null,
  });

  const handleShow = () => {
    dispatchReducer({ type: "5" });
  };
  const handleShowEdit = () => {
    dispatchReducer({ type: "SHOW", typeModal: "EDITAR" });
  };
  const handleShowAU = () => {
    dispatchReducer({ type: "2" });
  };
  const handleShowAUE = (item) => {
    setRow(item);
    dispatchReducer({ type: "3" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
    setVermarcaciones(false);
  };

  const handleShowEliminar = () => {
    Swal.fire({
      title: "Eliminar Paciente",
      text: `Estas seguro que desea eliminar al paciente ${items[0].NombrePaciente}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado",
          "Paciente Eliminado.",
          "success",
          history.push("/clientes"),
          deletePaciente(id)
        );
      }
    });
  };

  const [items, setItems] = useState([]);
  const [List, setList] = useState();
  const [Cliente] = useState("Profesional");
  const [colornivel, setcolornivel] = useState();
  const [listLegajo, setListLegajo] = useState([]);
  const [listProfesional, setListProfesional] = useState([]);
  const [edad, setEdad] = useState();
  const location = useLocation();
  const [isColored, setIsColored] = useState(false);
  const [activar, setactivar] = useState();
  const { id } = location.state;
  const [Estado, setEstado] = useState();
  const EstadousuarioCambiar = async () => {
    await Swal.fire({
      input: "textarea",
      inputLabel: "Motivo",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const motivo = result.value;
        Swal.fire(
          "Cambiado",
          "Estado Actualizado.",
          "success",
          EstadoPaciente({ id, motivo }),
          assigmentValue()
        );
      }
    });
  };

  const assigmentValue = useCallback(async () => {
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const result = await getPacientesById(id);
      if (result[0].estaActivo === "Activo") {
        setEstado(true);
        setIsColored("green");
        setactivar("Inhabilitar");
      } else {
        setEstado(false);
        setIsColored("red");
        setactivar("Habilitar");
      }
      const resultFicha = await getLegajoByPaciente(id);
      setListLegajo(resultFicha);
   
      setItems(result);
      const resultProfesional = await getAsignacionByPaciente(id);
      setListProfesional(resultProfesional);
      var hoy = new Date();
      var cumpleanos = new Date(result[0].FechaNacimiento);
      var edadnow = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edadnow--;
      }
      setEdad(edadnow);
      if (result[0].nivel === "Nivel 1") {
        setcolornivel("green");
      } else if (result[0].nivel === "Nivel 2") {
        setcolornivel("orange");
      } else {
        setcolornivel("red");
      }
      Swal.close();
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);

  const onReturnDataCliente = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      const result = await updatePaciente(data);
      if (result) {
        Swal.fire({
          title: " Datos Usuario del Servicio ",
          html: `Actualizados`,
          icon: "success",
        });
        assigmentValue();
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const onReturnupdateAsignacion = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      const result = await updateAsignacion(data);
      if (result) {
        Swal.fire({
          title: "Asignacion Actualizada",
          html: `Correctamente`,
          icon: "success",
        });
        assigmentValue();
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const EliminarAsignacion = async (data) => {
    Swal.fire({
      title: "Eliminar Asignacion",
      text: `Estas seguro que desea eliminar la Asignacion ${data.UsuarioAsignado}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado",
          "Cuenta Eliminado.",
          "success",
          delteAsignacion(data.IdProfesionalPaciente),
          assigmentValue()
        );
      }
    });
  };
  const onReturnAsignarUsuario = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      const result = await insertAsignacion({
        ...data,
        idPaciente: id,
        NombrePaciente: items[0].NombrePaciente,
      });
      if (result) {
        Swal.fire({
          title: "Profesional asignado",
          html: `Asigando Correctamente`,
          icon: "success",
        });
        const result = await getAsignacionByPaciente(id);
        setListProfesional(result);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  /*const CalculateHours = (
    fechaBegin,
    fechaEnd,
    tarifa,
    honorario,
    HoraEntrada,
    HoraSalida
  ) => {
    var fecha1 = moment(fechaBegin + " " + HoraEntrada, "YYYY-MM-DD HH:mm:ss");
    var fecha2 = moment(fechaEnd + " " + HoraSalida, "YYYY-MM-DD HH:mm:ss");

    var diff = fecha2.diff(fecha1, "m"); // Diff in hours
    return {
      canthoras: Math.round(diff / 60),
      totalHonorario: Math.round((honorario * diff) / 60),
      totalTarifa: Math.round((tarifa * diff) / 60),
    };
  };*/

  const onGenerarComision = () => {
    history.push({
      pathname: "/Liquidacion",
      state: items[0],
    });
  };
  const HandleMarcaciones = async () => {
    const resultUsuario = await getAllUsuario();
    const resultMapeado = resultUsuario.map((item) => {
      return { label: item.Nombre + " " + item.subtipo, value: item.IdUsuario };
    });
    const newList = [{ label: "TODOS", value: "TODOS" }, ...resultMapeado];
    setList(newList);
    dispatchReducer({ type: "4" });
  };
  const GenerarReporte = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      const listResult = await getLegajoByData({
        ...data,
        idPaciente: id,
        idUsuario: data.ID,
      });
      exportToExcel(listResult, "Reporte De Marcacion");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {items.map((item) => {
        return (
          <div key={item.IdPacientes}>
            <div className="col-xl-12 ">
              <div className="page-titlesFichaClientes">
                <div className="row" >
                <div className="col">
                  <h3 className="heading sm-mb-0">{item.NombrePaciente}</h3>

                  <span  className="heading sm-mb-0" style={{  color: `${isColored}` }}>
                    {" "}
                    {item.estaActivo}
                  </span>
                  <span
                    className="m-2"
                    style={{ fontSize: "18px", color: `gray` }}
                    hidden={Estado}
                  >
                    {" "}
                    {item.motivo}
                  </span>
                </div>

                <div className="d-flex  flex-row-reverse align-items-center flex-wrap col">
                  <Dropdown icon={<MenuIcon />} noCaret placement="leftStart">
                    <Dropdown.Item
                      icon={<CharacterAuthorizeIcon />}
                      onClick={handleShowAU}
                    >
                      {" "}
                      Asignar Profesional
                    </Dropdown.Item>
                    <Dropdown.Item icon={<EventDetailIcon />}>
                      Hoja de enfermeria
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<CalendarIcon />}
                      onClick={onGenerarComision}
                    >
                      Liquidacion
                    </Dropdown.Item>
                    <Dropdown.Item icon={<PageIcon />} onClick={handleShow}>
                      {" "}
                      Ver Pdf
                    </Dropdown.Item>
                    <Dropdown.Item icon={<EditIcon />} onClick={handleShowEdit}>
                      {" "}
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<MinusRoundIcon />}
                      onClick={EstadousuarioCambiar}
                    >
                      {" "}
                      {activar}
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<CloseOutlineIcon />}
                      onClick={handleShowEliminar}
                    >
                      {" "}
                      Eliminar
                    </Dropdown.Item>
                  </Dropdown>
                </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-xxl-4 ">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Contacto</h4>
                        <span
                          style={{ fontSize: "18px", color: `${colornivel}` }}
                        >
                          {" "}
                          {item.nivel}
                        </span>
                      </div>
                      <div className="card-body p-4">
                        <strong>Facturacion</strong>
                        <br></br>
                        <span>Razon Social : {item.RazonSocial} </span>
                        <br></br>
                        <span>Ruc : {item.Ruc}</span>
                        <br></br>
                        <span>Tarifa: {item.tarifa}</span>
                        <br></br>
                        <span>
                          Tiempo de Asistencia : {item.tiempoAsistencia}{" "}
                        </span>
                        <br></br>
                        <strong>Encargado</strong>
                        <br></br>
                        <span>Nombre : {item.FamiliarResponsable} </span>
                        <br></br>
                        <span>CI : {item.CedulaResponsable} </span>
                        <br></br>
                        <span>Telefono : {item.Celular}</span>
                        <br></br>
                        <strong>Otros</strong>
                        <br></br>
                        <span>Como conocio EethSA : {item.comoconocio} </span>
                        <br></br>
                        <span>Observacion : {item.obs1} </span>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-xxl-8">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Ficha</h4>
                      </div>
                      <div className="card-body p-4">
                        <strong>Datos Personales</strong>
                        <br></br>
                        <span>
                          {" "}
                          Fecha de Nacimiento : {item.FechaNacimiento}
                        </span>
                        <br></br>
                        <span>Edad : {edad}</span>
                        <br></br>
                        <span>CI : {item.Cedula}</span>
                        <br></br>
                        <span>Telefono : {item.Telefono} </span>
                        <br></br>
                        <span>Correo : {item.Correo}</span>
                        <br></br>
                        <span>Ciudad : {item.Ciudad}</span>
                        <br></br>
                        <span>Direccion : {item.Direccion}</span>
                        <br></br>

                        <strong>Datos Medicos</strong>
                        <br></br>
                        <span>Peso : {item.peso} Kg</span>
                        <br></br>
                        <span>Altura : {item.altura} m</span>
                        <br></br>
                        <span>Sexo : {item.Sexo} </span>
                        <br></br>
                        <span style={{ color: "red" }}>
                          Alergias a medicamentos : {item.alergiasamedicamentos}
                        </span>
                        <br></br>
                        <span style={{ color: "red" }}>
                          Alergias en general : {item.alergiasgral}
                        </span>
                        <br></br>
                        <span>
                          Diagnostico Medico : {item.diagnosticoMedico}{" "}
                        </span>
                        <br></br>
                        <span>Enfermedad de base : {item.enfermedadBase}</span>
                        <br></br>

                        <span>Movilidad Propia : {item.movilidadPropia} </span>
                        <br></br>
                        <span>
                          Equipo de Rehabilitacion : {item.equipoRehabilitacion}{" "}
                        </span>
                        <br></br>
                        <span>
                          Forma de Alimentacion : {item.formaAlimentacion}{" "}
                        </span>
                        <br></br>
                        <span>
                          Via de Alimentacion : {item.viadealimentacion}{" "}
                        </span>
                        <br></br>

                        <span>Tiempo de Guardia : {item.Guardiahs} </span>
                        <br></br>
                        <span>Observacion : {item.obs2} </span>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="col-xl-12 col-xxl-12">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Usuarios Asignados</h4>
                        </div>
                        <div className="card-body p-6">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            striped
                          >
                            <thead className="thead-primary">
                              <tr>
                                <th scope="col">Usuario</th>
                                <th scope="col">Guardia HS</th>
                                <th scope="col">Honorario</th>
                                <th scope="col">Tarifa</th>
                                <th scope="col">Modalidad</th>
                                <th scope="col">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listProfesional.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.UsuarioAsignado}</td>
                                  <td>{item.guardiahs}</td>
                                  <td>{item.honorarioenfermero}</td>
                                  <td>{item.tarifahora}</td>
                                  <td>{item.Modalidad}</td>
                                  <td scope="col">
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => handleShowAUE(item)}
                                    >
                                      Editar
                                    </button>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => EliminarAsignacion(item)}
                                    >
                                      Eliminar
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Historial</h4>
                    <Button onClick={HandleMarcaciones}>
                      Descargar Marcaciones
                    </Button>
                  </div>
                  <div className="card-body p-4">
                    <Table
                      responsive
                      className="primary-table-bordered"
                      striped
                    >
                      <thead className="thead-primary">
                        <tr>
                          <th scope="col">Usuario</th>
                          <th scope="col">Entrada</th>
                          <th scope="col">Entrada hs</th>
                          <th scope="col">Salida</th>
                          <th scope="col">Salida Hs</th>
                          <th scope="col">Cant. Hs</th>
                          <th scope="col">Honorario</th>
                          <th scope="col">Tarifa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listLegajo.slice(0, 11).map((item, index2) => {
                          return (
                            <tr key={index2}>
                              <td>{item.Usuario}</td>
                              <td>{item.Fecha}</td>
                              <td>{item.HoraEntrada}</td>
                              <td>{item.FechaSalida}</td>
                              <td>{item.HoraSalida}</td>
                              <td>{item.horascalculadas}</td>
                              <td>{item.totalhonorarioenfermero}</td>
                              <td>{item.totaltarifa}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {reducer.isShow && (
        <ModalCliente
          estado={reducer.isShow}
          typeModal={reducer.typeModal}
          onCloseModal={onCloseModal}
          idpaciente={items}
          onReturnValue={onReturnDataCliente}
        />
      )}
      <ModalMarcaciones
        estado={Vermarciones}
        onCloseModal={onCloseModal}
        idpaciente={items}
      />

      {reducer.isShowAsignarUsuario && (
        <ModalAsignarUsuario
          estado={reducer.isShowAsignarUsuario}
          typeModal={reducer.typeModal}
          onCloseModal={onCloseModal}
          idpaciente={items}
          onReturnValue={onReturnAsignarUsuario}
        />
      )}
      {reducer.isShowAsignarUsuarioEdit && (
        <ModalAsignarUsuarioEdit
          estado={reducer.isShowAsignarUsuarioEdit}
          typeModal={reducer.typeModal}
          onCloseModal={onCloseModal}
          dataedit={defaultValue}
          onReturnValue={onReturnupdateAsignacion}
        />
      )}
      {reducer.isShowDescargaMarcaciones && (
        <ModalDescargaSesiones
          estado={reducer.isShowDescargaMarcaciones}
          onCloseModal={onCloseModal}
          onReturnValue={GenerarReporte}
          Lista={List}
          ClienteOCuenta={Cliente}
        />
      )}
      {reducer.isShowModalPDF && (
        <ModalPreviewCliente
          estado={reducer.isShowModalPDF}
          onCloseModal={onCloseModal}
          onReturnValue={GenerarReporte}
          datos={items[0]}
        />
      )}
    </>
  );
};
export default FichaCliente;
