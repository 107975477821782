import React, { useState, useEffect, useCallback} from "react";
import { getUserCredentials, getUsuarioById } from "../../../api/usuarioApi";
/// Scroll
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
/// Image
const Header = () => {
  const userdata=localStorage.getItem("datauser")
  const Preview =localStorage.getItem("imgurl")
  //For header fixed
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  //end

  return (
    <div className={`header ${headerFix ? "sticky" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right">
              <li className="nav-item ">
                <Dropdown className="dropdown header-profile2">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer"
                  >
                    <div className="header-info2 d-flex align-items-center">
                      <div className="d-flex align-items-center sidebar-info">
                        <div>
                          <h4 className="mb-0">{userdata}</h4>
                        </div>
                      </div>
                      <img src={Preview} alt="" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="right"
                    className="mt-3 dropdown-menu dropdown-menu-end"
                  >
                    
                    <LogoutPage />
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
