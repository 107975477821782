import { useRef, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { useDownloadExcel } from "react-export-table-to-excel";
import genPDF from "./pdfWriter"
const ModalPreviewUsuario = (props) => {
  const { estado, onCloseModal, datos ,cargo,edad,mescumple,preview} = props;
  const tableRef = useRef(null);
  const [isShow,setisShow]=useState(true)
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${datos.Nombre}.${datos.Cedula}`,
    sheet: "Users",
  });
  const usoInterno = () => {
    const isShow=true
    genPDF({datos,preview,isShow,cargo,edad,mescumple})
  };
  const usoExterno2 = () => {
    const isShow=false
    genPDF({datos,preview,isShow,cargo,edad,mescumple})
    
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Vista Previa Ficha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
          <div ref={tableRef} id="content-id"  >
        <div>
         
          <br></br>
          <br></br>
        </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              
                flexDirection:"column",
                marginBottom : "30px"
              }}
            >
                <img
                className="p-2"
                style={{ width: 230 }}
                src="../../../../eethsalogo.jpg"
              /> 
              
              <h3>Ficha Profesional EETHSA</h3>
            </div>

            <div className=" row ">
              <div className="col">
                <div
                  className=" p-2"
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <div className="">
                    {" "}
                    <img
                      src={preview}
                      className="card-img-top"
                      style={{ height: "300px", width: "270px" }}
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card-body p-2">
                  <div className="">
                    <h3>DATOS PERSONALES</h3>
                    <span style={{ fontSize: 15 }}>
                      <strong>Nombre Completo : </strong>
                      {datos.Nombre}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>CI : </strong>
                      {datos.Cedula}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Sexo : </strong>
                      {datos.sexo}{" "}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Estado Civil: </strong>
                      {datos.estadocivil}{" "}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Hijos : </strong>
                      {datos.hijos}{" "}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Fecha de Nacimiento : </strong>{" "}
                      {datos.Fechadenacimiento}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Edad :</strong>{edad}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Mes de Cumpleaños: </strong>{mescumple}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Antecedentes Policiales : </strong>
                      {datos.antpol}
                    </span>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <div
                  className=" p-2 mr-2"
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <div className="">
                    <h3>CUENTA</h3>
                    <span style={{ fontSize: 15 }}>
                      <strong>Usuario : </strong>
                      {datos.Usuario}{" "}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Cargo : </strong>{cargo}{" "}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Profesion : </strong>{" "} {datos.subtipo}
                    </span>
                   
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Registro Profesional : </strong>{" "}
                      {datos.RegistroProfesional}{" "}
                    </span>
                    <br></br>
                    <span style={{ fontSize: 15 }}>
                      <strong>Fecha de Ingreso : </strong> {datos.Fechaingreso}
                    </span>
                    <br></br>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card-body  p-2">
                  <h3>CONTACTO</h3>
                
                  
                  <span style={{ fontSize: 15 }}>
                    <strong>Departamento : </strong>
                    {datos.departamento}{" "}
                  </span>
                  <br></br>
                  <span style={{ fontSize: 15 }}>
                    <strong>Ciudad : </strong>
                    {datos.Ciudad}{" "}
                  </span>
                  <br></br>
                  <span style={{ fontSize: 15 }}>
                    <strong>Barrio : </strong> {datos.barrio}
                  </span>
                  <br></br>
                  <span style={{ fontSize: 15  }}>
                    <strong>Direccion : </strong> {datos.direccion}
                  </span> 
                  <br></br>
                
                  <span style={{ fontSize: 15 }}>
                    <strong>Telefono: </strong> {datos.Telefono} 
                  </span> 
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5 p-4 mt-2">
            
             
            </div>
          </div>
          <div style={{ justifyContent: "center",
                alignItems: "center",
                display: "flex",
                }}>
          <ButtonGroup aria-label="Basic example" >
            <Button variant="danger" onClick={onCloseModal}>
              Cerrar
            </Button>
            <Button
              variant="primary"
              onClick={()=>{
                usoInterno();
              }}
            >
              Descargar PDF (uso interno)
            </Button>
          
          <Button
              variant="primary"
              onClick={()=>{
                usoExterno2();
              }}
            >
              Descargar PDF (uso Externo)
            </Button> 
            <br></br>
            <Button style={{backgroundColor:"#4caf50"}} onClick={onDownload}>
              Excel
            </Button>
          </ButtonGroup>
          </div>
       
        </Modal.Body>
       
      </Modal>
    </>
  );
};

export default ModalPreviewUsuario;
