import React,{useState, useEffect} from "react";

import { Link } from "react-router-dom";
/// Scroll
import { Dropdown } from "react-bootstrap";


import LogoutPage from './Logout';

/// Image
import profile from "../../../images/user.jpg";

import alb from '../../../images/svg/albania.svg';




const Header2 = ({ onNote }) => {
  const [searchBut, setSearchBut] = useState(false);	
  const [selectImage, setSelectImage] = useState([alb,'Albania']);	

  //For header fixed 
  	const [headerFix, setheaderFix] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 
  //end
  
  
  
  
  return ( 
    <div className={`header ${ headerFix ? "sticky" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
				<div className="input-group search-area">
					<input type="text" 
						className={`form-control ${searchBut ? "active" : ""}`}
						placeholder="Search here..." 
					/>
					<span className="input-group-text">
						<Link to={"#"}>
							<svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF"/>
							</svg>
						</Link>
					</span>
				</div>			
            </div>
			<ul className="navbar-nav header-right">
					
              
				
			
				
				
				
			  
				
			   
			    <li className="nav-item ">
					<Dropdown className="dropdown header-profile2">
						<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">							
							<div className="header-info2 d-flex align-items-center">
								<div className="d-flex align-items-center sidebar-info">
									<div>
										<h4 className="mb-0">Usuario</h4>
										
									</div>
								</div>
								<img src={profile}  alt="" />
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
							<LogoutPage />
						</Dropdown.Menu>
					</Dropdown>	
			    </li>  	
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header2;