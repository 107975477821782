import React, { useState, useEffect, useCallback,Fragment} from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import { getUserCredentials, getUsuarioById } from "../../../api/usuarioApi";

const NavBlog = () => {
  
  return (
    <Fragment>
     
      <NavHader />
      
      <Header
      />
      <SideBar />
    </Fragment>
  );
};

export default NavBlog;
