import { useRef, useCallback, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useReducer } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { CLIENTE_COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";


const ModalMarcaciones = (props) => {
    const {
        children,
        estado,
        x,
        typeModal,
        legajoObject,
        onCloseModal,
        idpaciente,
        onReturnValue
    } = props;
    const [data, setdata] = useState([]);
    const columns = useMemo(() => CLIENTE_COLUMNS, []);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },

        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;

    const [show, setShow] = useState(true);
    const [startDate, setStartDate] = useState(new Date());

    return (
        <>

            <Modal
                show={estado}
                onHide={onCloseModal}
                size="xl"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Historial de marcaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Fecha Inicial</span>
                    <br></br>
                    <DatePicker
                        className="form-control"
                        closeOnScroll={true}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                    />
                    <br></br>
                    <span>Fecha Final</span>
                    <br></br>
                    <DatePicker
                        className="form-control"
                        closeOnScroll={true}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                    />
                    <span>Historial de</span>
                    <br></br>
                    <div className="">
                        <select
                            className="form-control"
                            id="val-skill"
                            name="sexo"
                        >
                            <option value="Hombre">Usuario</option>
                            <option value="Mujer">Todo</option>
                        </select>
                        <br></br>
                        <Button type="Submit" >
                            Buscar
                        </Button>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()}>
                                                    {column.render("Header")}

                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="">
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {" "}
                                                            {cell.render("Cell")}{" "}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>
                                    {""}
                                </span>
                                <span className="table-index">
                                    Go to page :{" "}
                                    <input
                                        type="number"
                                        className="ml-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(pageNumber);
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center mb-3">
                                <div className="filter-pagination  mt-3">
                                    <button
                                        className=" previous-button"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        {"<<"}
                                    </button>

                                    <button
                                        className="previous-button"
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className="next-button"
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        Next
                                    </button>
                                    <button
                                        className=" next-button"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        {">>"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onCloseModal}>
                        Salir
                    </Button>

                </Modal.Footer>
            </Modal>


        </>
    );
}

export default ModalMarcaciones;
