import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { useCallback } from "react";
import { getUsuarioById, updateUsuario } from "../../api/usuarioApi";
import { DeleteUsuario, EstadoUsuario } from "../../api/usuarioApi";
import { getAllLegajoByOneUsuario } from "../../api/marcacionApi";
import ModalPreviewUsuario from "../components/sis/ModalUsuario/ModalPreviewUsuario.js";
import { Dropdown } from "rsuite";
import PageIcon from "@rsuite/icons/Page";
import MenuIcon from "@rsuite/icons/Menu";
import TagLockIcon from "@rsuite/icons/TagLock";
import EditIcon from "@rsuite/icons/Edit";
import MinusRoundIcon from "@rsuite/icons/MinusRound";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import "rsuite/dist/rsuite-no-reset.min.css";
import { resetPassword } from "../../api/usuarioApi";
import Swal from "sweetalert2";
import EditUser from "../components/sis/ModalUsuario/EditUser";
import Modalpass from "../components/sis/ModalUsuario/ModalPass.js";
import {
  getPacienteByProfesional,
  getAllPaciente,
} from "../../api/pacienteApi";
import { getLegajoByData } from "../../api/marcacionApi";
import { exportToExcel } from "react-json-to-excel";
import ModalDescargaSesiones from "../components/sis/modalreportes/ModalDescargaSesiones";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
      };
    case "SHOW2":
      return { isShow2: true, typeModal: state.typeModal };
    case "SHOW3":
      return { isShow3: true, typeModal: state.typeModal };
    case "4":
      return {
        isShowDescargaMarcaciones: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return {
        ...curShow,
        isShow: false,
        isShow2: false,
        isShow3: false,
        isShowDescargaMarcaciones: false,
      };
  }
};

const FichaUser = () => {
  const history = useHistory();
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
    isShow3: false,
    isShowDescargaMarcaciones: false,
  });
  const handleShow3 = () => {
    dispatchReducer({ type: "SHOW2", typeModal: "USUARIO" });
  };
  const handleShow = () => {
    dispatchReducer({ type: "SHOW" });
  };
  const handleShow2 = () => {
    dispatchReducer({ type: "SHOW3" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const HandleMarcaciones = async () => {
    const resultUsuario = await getAllPaciente();
    const resultMapeado = resultUsuario.map((item) => {
      return { label: item.NombrePaciente, value: item.IdPacientes };
    });
    const newList = [{ label: "TODOS", value: "TODOS" }, ...resultMapeado];
    setList(newList);
    dispatchReducer({ type: "4" });
  };
  const GenerarReporte = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      const listResult = await getLegajoByData({
        ...data,
        idPaciente: data.ID,
        idUsuario: id,
      });
      exportToExcel(listResult, "Reporte De Marcacion");
    } catch (err) {
      console.log(err);
    }
  };
  const [items, setItems] = useState([]);
  const [List, setList] = useState();
  const [Cliente] = useState("Usuario del Servicio");
  const [edad, setEdad] = useState();
  const [MesCumple, setMesCumple] = useState();
  const [isColored, setIsColored] = useState(false);
  const [Estado, setEstado] = useState();
  const [preview, setPreview] = useState();
  const [activar, setactivar] = useState();
  const [TipoCuenta, setTipoCuenta] = useState();
  const location = useLocation();
  const { id } = location.state;
  const [listMarcacion, setListMarcacion] = useState([]);
  const [listProfesional, setListProfesional] = useState([]);
  const assigmentValue = useCallback(async () => {
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });

      const listResult = await getAllLegajoByOneUsuario(id);
      setListMarcacion([listMarcacion, ...listResult]);
      const resultProfesional = await getPacienteByProfesional(id);
      setListProfesional(resultProfesional);

      const result = await getUsuarioById(id);
      setItems(result);

      const res = await fetch(
        `https://eethsa.xyz/eethsa1/${result[0].imagen}`
      );
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setPreview(imageObjectURL);
      var hoy = new Date();

      var cumpleanos = new Date(result[0].Fechadenacimiento);
      cumpleanos.setMinutes(
        cumpleanos.getMinutes() + cumpleanos.getTimezoneOffset()
      );
      const currMonth = new Date(cumpleanos).toLocaleString([], {
        month: "long",
      });
      var edadnow = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();
      if (result[0].estaActivo === "Activo") {
        setEstado("Activo");
        setIsColored(true);
        setactivar("Inhabilitar");
      } else {
        setEstado("Inactivo");
        setIsColored(false);
        setactivar("Habilitar");
      }

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edadnow--;
      }
      if (result[0].IdTipoUsuario == 2) {
        setTipoCuenta("Enfermeria");
      }
      if (result[0].IdTipoUsuario == 3) {
        setTipoCuenta("Logistica");
      }

      if (result[0].Fechadenacimiento == null) {
        setEdad("");
      } else {
        setEdad(edadnow);
        setMesCumple(currMonth);
      }
      Swal.close();
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  }, [id]);
  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  const Eliminar = () => {
    Swal.fire({
      title: "Eliminar Cuenta",
      text: `Estas seguro que desea eliminar la Cuenta ${items[0].Nombre}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado",
          "Cuenta Eliminado.",
          "success",
          history.push("/usuarios"),
          DeleteUsuario(items[0].IdUsuario)
        );
      }
    });
  };
  const EstadousuarioCambiar = async () => {
    const IdUsuario = items[0].IdUsuario;

    await Swal.fire({
      input: "textarea",
      inputLabel: "Motivo",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const motivo = result.value;
        Swal.fire(
          "Cambiado",
          "Estado Actualizado.",
          "success",
          EstadoUsuario({ IdUsuario, motivo }),
          assigmentValue()
        );
      }
    });
  };

  const onReturnUsuarioData = async (data) => {
    console.log(data);
    try {
      Swal.fire({
        title: "Cargando",
        html: "Cargando  datos", // add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const result = await updateUsuario({
        ...data,
        IdUsuario: items[0].IdUsuario,
      });
      if (result) {
        Swal.close();
        Swal.fire({
          title: "Usuario",
          html: `Se guardo correctamente el usuario`,
          icon: "success",
        });
        assigmentValue();
        dispatchReducer({ type: "END" });
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const onReturnPass = async (data) => {
    dispatchReducer({ type: "END" });
    console.log(data, items[0].IdUsuario);
    try {
      Swal.fire({
        title: "Actualizando",
        html: "Actualizando  datos", // add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const result = await resetPassword({
        ...data,
        IdUsuario: items[0].IdUsuario,
      });
      if (result) {
        Swal.close();
        Swal.fire({
          title: "Usuario",
          html: `Se Actualizo Correctamente`,
          icon: "success",
        });
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  return (
    <>
      {items.map((item) => {
        return (
          <div key={item.IdUsuario}>
            <div className="col-xl-12">
              <div className="page-titles acti-space">
                <h2 className="heading sm-mb-0 mb-2">Cuenta </h2>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="me-2">
                    <Dropdown icon={<MenuIcon />} noCaret placement="leftStart">
                      <Dropdown.Item icon={<PageIcon />} onClick={handleShow}>
                        {" "}
                        Descargar Ficha
                      </Dropdown.Item>
                      <Dropdown.Item
                        icon={<TagLockIcon />}
                        onClick={handleShow2}
                      >
                        {" "}
                        Cambiar Contraseña
                      </Dropdown.Item>
                      <Dropdown.Item icon={<EditIcon />} onClick={handleShow3}>
                        {" "}
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item
                        icon={<MinusRoundIcon />}
                        onClick={EstadousuarioCambiar}
                      >
                        {" "}
                        {activar}
                      </Dropdown.Item>
                      <Dropdown.Item
                        icon={<CloseOutlineIcon />}
                        onClick={Eliminar}
                      >
                        {" "}
                        Eliminar
                      </Dropdown.Item>
                    </Dropdown>

                    {reducer.isShow && (
                      <ModalPreviewUsuario
                        estado={reducer.isShow}
                        onCloseModal={onCloseModal}
                        datos={item}
                        edad={edad}
                        mescumple={MesCumple}
                        cargo={TipoCuenta}
                        preview={preview}
                      />
                    )}
                  </div>
                  {reducer.isShow2 && (
                    <EditUser
                      estado={reducer.isShow2}
                      datos={items}
                      typeModal={reducer.typeModal}
                      onCloseModal={onCloseModal}
                      onReturnValue={onReturnUsuarioData}
                      img={preview}
                    />
                  )}
                  {reducer.isShow3 && (
                    <Modalpass
                      estado={reducer.isShow3}
                      onCloseModal={onCloseModal}
                      onReturnValue={onReturnPass}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="clearfix">
                    <div className="card card-bx profile-card author-profile m-b30">
                      <div className="card-body">
                        <div className="p-5">
                          <div className="author-profile">
                            <div className="author-media">
                              <img src={preview} alt="" />
                            </div>
                            <div className="author-info">
                              <h6 className="title">{item.Nombre} </h6>
                              <span>{TipoCuenta}</span>
                            </div>
                          </div>
                        </div>
                        <div className="info-list">
                          <ul>
                            <li>
                              <a>Usuario</a>
                              <span>{item.Usuario}</span>
                            </li>
                            <li>
                              <a href="uc-lightgallery.html">Profesion</a>
                              <span>{item.subtipo}</span>
                            </li>
                            <li>
                              <a>Registro Profesional</a>
                              <span>{item.RegistroProfesional}</span>
                            </li>
                            <li>
                              <a>Fecha de Ingreso</a>
                              <span>{item.Fechaingreso}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="input-group mb-3">
                          <div className="form-control rounded text-center bg-white">
                            <strong
                              style={{ color: isColored ? "green" : "red" }}
                            >
                              {Estado}
                            </strong>
                          </div>
                        </div>

                        <div
                          href="https://www.dexignlab.com/"
                          className="orm-control rounded text-center bg-white"
                        >
                          <span style={{ fontSize: 15 }}>
                            {Estado === "Activo" ? (
                              ""
                            ) : (
                              <strong>Motivo Inactivo: {item.motivo}</strong>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="card profile-card card-bx m-b30">
                    <div className="card-header">
                      <h6 className="title">Datos Personales</h6>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4 m-b30">
                          <label className="form-label">
                            Cedula de Identidad
                          </label>
                          <span type="text" className="form-control">
                            {item.Cedula}
                          </span>
                          <label className="form-label mt-3">
                            Fecha de Nacimiento
                          </label>
                          <span type="text" className="form-control">
                            {item.Fechadenacimiento}
                          </span>
                          <label className="form-label mt-3">Edad</label>
                          <span type="text" className="form-control">
                            {edad}
                          </span>
                        </div>
                        <div className="col-sm-4 m-b30">
                          <label className="form-label">Sexo</label>
                          <span type="text" className="form-control">
                            {item.sexo}
                          </span>
                          <label className="form-label mt-3">
                            Estado Civil
                          </label>
                          <span type="text" className="form-control">
                            {item.estadocivil}
                          </span>
                          <label className="form-label mt-3">Hijos</label>
                          <span type="text" className="form-control">
                            {item.hijos}
                          </span>
                        </div>
                        <div className="col-sm-4 m-b30">
                          <label className="form-label ">
                            Mes de cumpleaños
                          </label>
                          <span type="text" className="form-control">
                            {MesCumple}
                          </span>
                          <label className="form-label mt-3">
                            Atecedentes policiales
                          </label>
                          <span className="form-control" rows="5">
                            {item.antpol}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h6 className="title">Contacto</h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4 m-b30">
                          <label className="form-label">Telefono</label>
                          <span type="text" className="form-control">
                            {" "}
                            {item.Telefono}
                          </span>
                        </div>
                        <div className="col-sm-4 m-b30">
                          <label className="form-label">Departamento</label>
                          <span type="text" className="form-control">
                            {item.departamento}
                          </span>
                          <label className="form-label mt-3">Ciudad</label>
                          <span type="text" className="form-control">
                            {item.Ciudad}
                          </span>
                        </div>
                        <div className="col-sm-4 m-b30">
                          <label className="form-label">Barrio</label>
                          <span type="text" className="form-control">
                            {item.barrio}
                          </span>
                          <label className="form-label mt-3">Direccion</label>
                          <span type="text" className="form-control">
                            {item.direccion}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-xxl-8">
                <div className="row"></div>
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Pacientes Asignados</h4>
                    </div>
                    <div className="card-body p-6">
                      <Table
                        responsive
                        className="primary-table-bordered col-6 "
                      >
                        <thead className="thead-primary">
                          <tr>
                            <th scope="col">Paciente</th>
                            <th scope="col">Guardia HS</th>
                            <th scope="col">Honorario</th>
                            <th scope="col">Tarifa</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listProfesional.map((item, index) => (
                            <tr key={index}>
                              <td>{item.NombrePaciente}</td>
                              <td>{item.guardiahs}</td>
                              <td>{item.honorarioenfermero}</td>
                              <td>{item.tarifahora}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Sesiones</h4>
                    <Button onClick={HandleMarcaciones}>
                      Descargar Marcaciones
                    </Button>
                    {reducer.isShowDescargaMarcaciones && (
                      <ModalDescargaSesiones
                        estado={reducer.isShowDescargaMarcaciones}
                        onCloseModal={onCloseModal}
                        onReturnValue={GenerarReporte}
                        Lista={List}
                        ClienteOCuenta={Cliente}
                      />
                    )}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive recentOrderTable">
                      <table className="table verticle-middle table-responsive-md">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Usuario</th>
                            <th scope="col">Fecha Entrada</th>
                            <th scope="col">Hora Entrada</th>
                            <th scope="col">ubicacion Entrada</th>
                            <th scope="col">Fecha Salida</th>
                            <th scope="col">Hora Salida</th>
                            <th scope="col">Ubicacion Salida</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listMarcacion.slice(0,20).map((item, index2) => {
                            return (
                              <tr key={index2}>
                                <td>{item.IdLegajoEnfermeria}</td>
                                <td>{item.Usuario}</td>
                                <td>{item.Fecha}</td>
                                <td>{item.HoraEntrada}</td>
                                <td>
                                  <a
                                    href={
                                      "https://www.google.com/maps/search/?api=1&query=" +
                                      item.Latitud +
                                      "," +
                                      item.Longitud
                                    }
                                    target="_blank"
                                  >
                                    <div>{item.Paciente}</div>
                                  </a>
                                </td>
                                <td>{item.FechaSalida}</td>
                                <td>{item.HoraSalida}</td>
                                <td>
                                  <a
                                    href={
                                      "https://www.google.com/maps/search/?api=1&query=" +
                                      item.latsalida +
                                      "," +
                                      item.longsalida
                                    }
                                    target="_blank"
                                  >
                                    <div>{item.Paciente}</div>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default FichaUser;
