import Swal from "sweetalert2";
import BaseSelect from "react-select";
import { Button, Modal } from "react-bootstrap";
import { usePagination, useTable } from "react-table";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AddLegajo,
  EditLegajo,
  deleteLegajo,
  GuardarLiquidacion,
  MarcarLegajoLiquidacion,
} from "../../../api/marcacionApi";
import { getAllUsuario } from "../../../api/usuarioApi";
import { GENERAR_LIQUIDACION_COLUMNS } from "../Columns";
import { calculateTotalHours } from "../../../utils/dates";
import FixRequiredSelect from "../../components/sis/modalcliente/FixRequiredSelect";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Select = (props) => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || []}
  />
);

const ModalGenerarliquidacionCliente = (props) => {
  const {
    estado,
    onCloseModal,
    onReturnValue,
    ModalData,
    fechafin,
    handleShow,
    Tarifa,
  } = props;
  const _ = require("lodash");
  const [botonguardar, setbotonguardar] = useState(false);
  const [data, setData] = useState([]);
  const [Count, setCount] = useState(0);
  const [editableRows, setEditableRows] = useState([]);
  const [listUsuarios, setListUsuarios] = useState([]);
  const [dis, setdis] = useState(false);
  const [totalHoras, setTotalHoras] = useState(
    parseFloat(_.sumBy(ModalData[0], (rowsDatas) => Number(rowsDatas.horascalculadas))).toFixed(2)
  );
  const [totalTarifa, setTotalTarifa] = useState(
    _.sumBy(ModalData[0], (rowsDatas) => Number(rowsDatas.totaltarifa))
  );

  // sumar todos los elementos
  const fechainicio = ModalData[0].Fecha;
  const fechafinal = ModalData[ModalData.length - 1].FechaSalida;

  const onGuardar = async () => {
    setbotonguardar(true)
    // boton Guardando..
    // generar dato de guardado  // id guardado , fecha inicio , fecha fin total y total horas
    if (Count > 0) {
      Swal.fire({
        title: "No se puede Guardar!",
        text: "Guarde la edicion para continuar",
        icon: "warning",
        showCancelButton: false,
      });
      setbotonguardar(false)
    } else {
      let IDPaciente = ModalData[0].IdPaciente;
      const IdLiquidacion = await GuardarLiquidacion({
        totalHoras,
        totalTarifa,
        fechainicio,
        fechafin,
        IDPaciente,
      });
      let IDliquidacion = IdLiquidacion.idLiquidacionClientes;
      const marcarlegajo = await MarcarLegajoLiquidacion({
        IDliquidacion,
        IDPaciente,
        fechafin,
      });
      onReturnValue(marcarlegajo);
    }
  };

  const tableColumns = useMemo(() => GENERAR_LIQUIDACION_COLUMNS, []);

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data,
      initialState: { pageSize: 1000, pageIndex: 0 },
    },
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const showLoading = () => {
    Swal.fire({
      title: "Ingresando",
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const hideLoading = () => {
    Swal.close();
  };

  const toggleRowEditability = (IdLegajoEnfermeria) => {

    if (editableRows.includes(IdLegajoEnfermeria)) {
      setCount(Count - 1)
      setEditableRows(editableRows.filter((row) => row !== IdLegajoEnfermeria));
    } else {
      setCount(Count + 1)
      setEditableRows([...editableRows, IdLegajoEnfermeria]);
    }
  };

  const handleCellChange = (rowIndex, columnId, value) => {
    let newValue = value;

    const regexTime = /^\d{2}:\d{2}:\d{2}\.\d{5}$/;
    const isTime = columnId === "HoraEntrada" || columnId === "HoraSalida";
    const isValidValue = regexTime.test(value);

    if (isTime && !isValidValue) {
      newValue += ".00000";
    }

    const updatedData = [...data];
    updatedData[rowIndex][columnId] = newValue;

    if (!updatedData[rowIndex].isNew) {
      updatedData[rowIndex].isEdit = true;
    }

    const isDateTimeChanged =
      columnId === "Fecha" ||
      columnId === "HoraEntrada" ||
      columnId === "FechaSalida" ||
      columnId === "HoraSalida";

    if (isDateTimeChanged) {
      const startDate = updatedData[rowIndex].Fecha;
      const startTime = updatedData[rowIndex].HoraEntrada;
      const endDate = updatedData[rowIndex].FechaSalida;
      const endTime = updatedData[rowIndex].HoraSalida;
      const totalHours =
        calculateTotalHours(startDate, startTime, endDate, endTime) || 0;

      updatedData[rowIndex].horascalculadas = totalHours;
      updatedData[rowIndex].totaltarifa =
        Math.round(totalHours * Number(updatedData[rowIndex].CostoHoraEmpresa) || 0);
    }

    setData(updatedData);
  };

  const handleAddNewRow = () => {
    setdis(true)
    const newRow = {
      IdLegajoEnfermeria: new Date().getTime(),
      IdPaciente: data[0].IdPaciente,
      IdUsuario: data[0].IdUsuario,
      Paciente: data[0].Paciente,
      Fecha: "", // START DATE
      HoraEntrada: "", // START TIME
      FechaSalida: "", // END DATE
      HoraSalida: "", // END TIME
      horascalculadas: 0, // TOTAL HOURS
      totaltarifa: 0, // TOTAL RATE
      Usuario: data[0].Usuario,
      CostoHoraEmpresa: Tarifa,
      isNew: true,
    };

    setData((oldData) => [...oldData, newRow]);
    toggleRowEditability(newRow.IdLegajoEnfermeria);
  };

  const handleChangeEnfermero = (index, selectedOption) => {
    const tmpData = [...data];

    tmpData[index] = {
      ...tmpData[index],
      IdUsuario: selectedOption.value,
      Usuario: selectedOption.label,
    };

    setData(tmpData);
  };

  const deleteDataById = (idLegajo) => {
    const tmpData = [...data].filter(
      (item) => item.IdLegajoEnfermeria !== idLegajo
    );
    setData(tmpData);
  };

  const handleDeleteRow = (IdLegajoEnfermeria) => {
    setdis(false)
    const selectedData = data.filter(
      (item) => item.IdLegajoEnfermeria === IdLegajoEnfermeria
    )[0];
    const selectedId = selectedData.IdLegajoEnfermeria;

    if (selectedData.isNew) {
      toggleRowEditability(selectedId);
      deleteDataById(selectedId);
      return;
    }

    Swal.fire({
      title: "Estas seguro?",
      text: "No se podra Revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        showLoading();
        deleteLegajo(selectedId)
          .then((result) => {
            if (Count > 0) {
              setCount(Count - 1)
            }
            deleteDataById(selectedId);
            Swal.fire({
              title: "Eliminado!",
              text: "Fue eliminado.",
              icon: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const formatTime = (value, type) => {
    if (type !== "time") {
      return value;
    }

    return value.split(".")[0];
  };

  const handleEditLegajo = async (objeto) => {
    try {
      const payload = {
        idLegajo: objeto.IdLegajoEnfermeria,
        fechaInicio: objeto.Fecha,
        fechafin: objeto.FechaSalida,
        horainicio: objeto.HoraEntrada,
        horafin: objeto.HoraSalida,
        totalhs: objeto.horascalculadas,
        totaltarifa: objeto.totaltarifa,
      };

      await EditLegajo(payload);

    } catch (error) {
      console.log(error);
    }
  };

  const handleAddLegajo = async (objeto) => {
    try {
      const payload = {
        idPaciente: objeto.IdPaciente,
        idUsuario: objeto.IdUsuario,
        paciente: objeto.Paciente,
        fechaInicio: objeto.Fecha,
        fechafin: objeto.FechaSalida,
        horainicio: objeto.HoraEntrada,
        horafin: objeto.HoraSalida,
        totalhs: objeto.horascalculadas,
        totaltarifa: objeto.totaltarifa,
        usuario: objeto.Usuario,
        tipo: fechafinal,
        tarifa: Tarifa
      };

      await AddLegajo(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const refetchData = async () => {
    await handleShow();
    // setData([...data]);
  };

  const handleSubmit = async (IdLegajoEnfermeria) => {
    setdis(false)
    if (editableRows.includes(IdLegajoEnfermeria)) {

      const selectedData = data.filter(
        (item) => item.IdLegajoEnfermeria === IdLegajoEnfermeria
      )[0];
      const isNew = !!selectedData.isNew;
      const isEdit = !!selectedData.isEdit;

      if (isNew || isEdit) {
        showLoading();
        if (isNew) {
          await handleAddLegajo(selectedData);
          await refetchData();
        } else if (isEdit) {
          await handleEditLegajo(selectedData);
          await refetchData();
        }
        hideLoading();
      }
    }
    toggleRowEditability(IdLegajoEnfermeria);
  };

  const selectedEnfermero = useMemo(() => {
    return listUsuarios.filter((item) => item.value === data[0].IdUsuario)[0];
  }, [listUsuarios, data]);

  const assigmentValue = useCallback(async () => {
    try {
      const resultUsuario = await getAllUsuario();
      const resultMapeado = resultUsuario.map((item) => {
        return { label: item.Usuario, value: item.IdUsuario };
      });

      setListUsuarios(resultMapeado);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const defaultEnfermero = useCallback(
    (data) => {
      return data.Usuario && data.IdUsuario
        ? { label: data.Usuario, value: data.IdUsuario }
        : selectedEnfermero;
    },
    [selectedEnfermero]
  );

  useEffect(() => {
    assigmentValue();
  }, []);

  useEffect(() => {
    setData(ModalData);
  }, [ModalData]);

  useEffect(() => {
    const totalHoras = data.reduce(
      (accumulator, item2) =>
        accumulator + Number(item2.horascalculadas +
          item2.horashoraextra),
      0
    );
    const totalTarifa = data.reduce(
      (accumulator, item2) =>
        accumulator + Number(item2.totaltarifa +
          item2.CostoHoraEmpresa *
          item2.horashoraextra),
      0
    );
    setTotalHoras(parseFloat(totalHoras).toFixed(2));
    setTotalTarifa(totalTarifa);
  }, [data]);

  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Generar Liquidacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-striped">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th  style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                    <th
                      colSpan="1"
                      role="columnheader"
                      style={{ width: "100px" }}
                    >
                      Opciones
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr

                      {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {editableRows.includes(
                              row.original.IdLegajoEnfermeria
                            ) && cell.column.isEditable ? (
                              <>
                                {cell.column.meta.type === "selectbox" ? (
                                  <div style={{ width: "140px" }}>
                                    <Select
                                      required
                                      isSearchable
                                      menuPosition="fixed"
                                      options={listUsuarios}
                                      isDisabled={!cell.row.original.isNew}
                                      defaultValue={defaultEnfermero(
                                        cell.row.original
                                      )}
                                      onChange={(selectedOption) =>
                                        handleChangeEnfermero(
                                          row.index,
                                          selectedOption
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <input
                                    step={1}
                                    max={fechafin}
                                    className="form-control"
                                    type={cell.column.meta.type}
                                    value={formatTime(
                                      cell.value,
                                      cell.column.meta.type
                                    )}
                                    onChange={(e) =>
                                      handleCellChange(
                                        row.index,
                                        cell.column.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              <>{cell.render("Cell")}</>
                            )}
                          </td>
                        );
                      })}

                      <td>
                        <div>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">{row.original.Observacion}</Tooltip>}
                          >
                            {({ ref, ...triggerHandler }) => (
                              <Button
                                size="sm"
                                variant="info"
                                {...triggerHandler}
                                ref={ref}
                                style={{ marginRight: "4px" }}
                                onClick={() =>
                                  handleSubmit(row.original.IdLegajoEnfermeria)
                                }
                              >
                                {editableRows.includes(
                                  row.original.IdLegajoEnfermeria
                                ) ? (
                                  <i
                                    className="material-icons"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {" "}
                                    save{" "}
                                  </i>
                                ) : (
                                  <i
                                    className="material-icons"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {" "}
                                    mode_edit{" "}
                                  </i>
                                )}
                              </Button>
                            )}
                          </OverlayTrigger>



                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              handleDeleteRow(row.original.IdLegajoEnfermeria)
                            }
                          >
                            <i
                              className="material-icons"
                              style={{ fontSize: "16px" }}
                            >
                              {" "}
                              delete{" "}
                            </i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>Total</td>
                  <td>{totalHoras}</td>
                  <td>{totalTarifa}</td>
                </tr>
              </tfoot>
            </table>
            <hr />
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={handleAddNewRow}
                className="btn btn-outline-primary m-2"
                disabled={dis}
              >
                Agregar Marcacion
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button disabled={botonguardar} onClick={onGuardar} form="userform">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalGenerarliquidacionCliente;
