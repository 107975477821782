import { CALL_API } from "../context/constValues";
import { getAuthToken } from "../context/auth-context";
export async function getAllFeriados() {
    const token = getAuthToken();
    const response = await fetch(`${CALL_API}/feriado`, {
      headers: { Authorization: "Bearer " + token },
    });
  
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Can not fetch the data with this parameter");
    }
  
    return data.result;
  }
  export async function insertFeriado(objeto) {
    const { fecha,fechanombre} = objeto;

    const token = getAuthToken();
    const response = await fetch(`${CALL_API}/feriado`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fecha: fecha,
        fechanombre:fechanombre
      }),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(
        data.message ? data.message : "Error no se pudo insertar la comision"
      );
    }
  
    return data.isSaved;
  }
  export async function updateFeriado(objeto) {
 
    const {
      idcalendarioferiado,
      fecha,
      fechanombre
    } = objeto;
    console.log(objeto)
    const token = getAuthToken();
    const response = await fetch(
      `${CALL_API}/feriado/${idcalendarioferiado}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idCalendario:idcalendarioferiado,
          fecha:fecha,
          fechanombre:fechanombre
        }),
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error("Can not fetch the data with this parameter");
    }
  
    return data.isSaved;
  }
  export async function deleteFeriado(id) {
    const token = getAuthToken();
    const response = await fetch(`${CALL_API}/feriado/${id}`, {
      method: "DELETE",
      headers: { Authorization: "Bearer " + token },
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error("Can not fetch the data with this parameter");
    }
  
    return data.isDeleted;
  }