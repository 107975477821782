import { useRef, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { useDownloadExcel } from "react-export-table-to-excel";
import genPDF from "./pdfWriter"
const ModalPreviewCliente = (props) => {
  const { estado, onCloseModal, datos} = props;
  console.log(datos)
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${datos.Nombre}.${datos.Cedula}`,
    sheet: "Users",
  });
  const usoInterno = () => {
    genPDF({datos})
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Vista Previa Ficha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
          <div ref={tableRef} id="content-id"  >
        <div>
         
          <br></br>
          <br></br>
        </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              
                flexDirection:"column",
                marginBottom : "30px"
              }}
            >
                <img
                className="p-2"
                style={{ width: 230 }}
                src="../../../../eethsalogo.jpg"
              /> 
              
              <h3>Ficha Usuario del servicio EETHSA</h3>
            </div>

            <div className="card-body p-2">
                  <div className="" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              
                flexDirection:"column",
                marginBottom : "30px"
              }}>
                    <h3>DATOS PERSONALES</h3>
                   <span style={{fontSize:"15px"}}>Nombre : {datos.NombrePaciente}</span>
                   <span  style={{fontSize:"15px"}}>Fecha de Nacimiento : {datos.FechaNacimiento}</span>
                   <span  style={{fontSize:"15px"}}> CI : {datos.Cedula}</span>
                   <span  style={{fontSize:"15px"}}> Telefono : {datos.Celular}</span>
                   <span  style={{fontSize:"15px"}}>Correo : {datos.Correo}</span>
                   <span  style={{fontSize:"15px"}}>Ciudad : {datos.Ciudad}</span>
                   <span  style={{fontSize:"15px"}}>Direccion : {datos.Direccion}</span>
                   <h3>Datos Medicos</h3>
                   <span  style={{fontSize:"15px"}}>Peso : {datos.peso}</span>
                   <span  style={{fontSize:"15px"}}>Altura : {datos.altura}</span>
                   <span  style={{fontSize:"15px"}}>Sexo : {datos.Sexo}</span>
                   <span  style={{fontSize:"15px"}}>Alergias a Medicamentos : {datos.alergiasamedicamentos}</span>
                   <span  style={{fontSize:"15px"}}>Alergias en General : {datos.alergiasgral}</span>
                   <span  style={{fontSize:"15px"}}>Enfermedad de base : {datos.enfermdadBase}</span>
                   <span  style={{fontSize:"15px"}}>Diagnostico Medico: {datos.diagnosticoMedico}</span>
                   <span  style={{fontSize:"15px"}}>Movilidad Propia : {datos.movilidadPropia}</span>
                   <span  style={{fontSize:"15px"}}>Equipo de Rehabilitacion : {datos.equipoRehabilitacion}</span>
                   <span  style={{fontSize:"15px"}}>Forma de Alimentacion : {datos.formaAlimentacion}</span>
                   <span  style={{fontSize:"15px"}}> Via de Alimentacion : {datos.viadealimentacion}</span>
                   <span  style={{fontSize:"15px"}}>Tiempo de guardia : {datos.guardiahs}</span>
                   <span  style={{fontSize:"15px"}}>Observacion Medica: {datos.obs2}</span>
                   <h3>Encargado & Facturacion</h3>
                   <span  style={{fontSize:"15px"}}>Razon Social : {datos.RazonSocial}</span>
                   <span  style={{fontSize:"15px"}}>Ruc : {datos.Ruc}</span>
                   <span  style={{fontSize:"15px"}}>Tiempo de Asistencia : {datos.tiempoAsistencia}</span>
                   <span  style={{fontSize:"15px"}}>Encargado : {datos.FamiliarResponsable}</span>
                   <span  style={{fontSize:"15px"}}>CI del Encargado: {datos.CedulaResponsable}</span>
                   <span  style={{fontSize:"15px"}}>Telefono  del Encargado: {datos.Telefono}</span>
                   <h3>Otros</h3>
                   <span  style={{fontSize:"15px"}}>Como Conocio EETHSA: {datos.comoconocio}</span>
                   <span  style={{fontSize:"15px"}}>Observacion: {datos.obs1}</span>
                   

                  </div>
                </div>
          </div>
          <div style={{ justifyContent: "center",
                alignItems: "center",
                display: "flex",
                }}>
          <ButtonGroup aria-label="Basic example" >
            <Button variant="danger" onClick={onCloseModal}>
              Cerrar
            </Button>
            <Button
              variant="primary"
              onClick={()=>{
                usoInterno();
              }}
            >
              Descargar PDF
            </Button>
        
            <br></br>
            <Button style={{backgroundColor:"#4caf50"}} onClick={onDownload}>
              Excel
            </Button>
          </ButtonGroup>
          </div>
       
        </Modal.Body>
       
      </Modal>
    </>
  );
};

export default ModalPreviewCliente;