import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import recibo from "../../../utils/recibo.jpg";
import NumerosALetras from "../../../utils/NumeroALetras";
export default function pdfrecibo(props) {
  const { nombrepaciente, nombreusuario, Honorario,idrec,fechain,fechafin} = props;
  var doc = new jsPDF();
  const today = new Date();
  let letra =NumerosALetras(Honorario)
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const formattedToday = dd + "/" + mm + "/" + yyyy;
  var totalPagesExp = "{total_pages_count_string}";
  doc.addImage(`${recibo}`, "JPEG", 0, 0, 210, 300);
  doc.setFontSize(9);
  doc.text(`${idrec}`, 20, 22);
  doc.text(`${nombreusuario}`, 75, 22);
  doc.text(`${Honorario} Gs.`, 100, 40);
  doc.text(`${letra}`, 90, 52);
  doc.text(`${nombrepaciente} del ${fechain} al ${fechafin}`, 90, 63);
  doc.text(`${formattedToday}`, 13, 143);
  // Footer
  doc.text(`${idrec}`, 20, 22+150);
  doc.text(`${nombreusuario}`, 75, 22+150);
  doc.text(`${Honorario} Gs.`, 100, 40+150);
  doc.text(`${letra}`, 90, 52+150);
  doc.text(`${nombrepaciente} del ${fechain} al ${fechafin}`, 90, 63+150);
  doc.text(`${formattedToday}`, 13, 143+150);
  var str = "Page " + doc.internal.getNumberOfPages();
  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    str = str + " of " + totalPagesExp;
  }

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
  doc.output("dataurlnewwindow");
  //doc.save(`liquidacion.pdf`);
}
