import { useRef, useCallback } from "react";
import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { getAllPaciente, getLocal } from "../../../api/pacienteApi";
import { useEffect, useReducer } from "react";
import { useState } from "react";
import { getUserCredentials } from "../../../api/usuarioApi";
import { getUserAsignaciones } from "../../../api/usuarioApi";
import { getAllTipoUsuarios } from "../../../api/tipoUsuarioApi";
import { UploadAnimation } from './animations/UploadAnimation.js'
import Swal from 'sweetalert2'
import Select from "react-select";
const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const Modale = (props) => {
  const handleChange2 = (event) => {
    setFormData2({
      name: event.target.name,
      value: event.target.value,
    });
  };
  const referencia = useRef();
  const uploadFiles = () => {

    referencia.current.click()
  }
  const [marcarEntrada, setmarcarEntrada] = useState(false)
  const [image, setImage] = useState(null)
  const [preview, setPreview] = useState('')
  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result.toString())
      }
      reader.readAsDataURL(image)
    } else {
      setPreview('');
    }
  })
  const [dishes] = useState([
    {
      label: "Enfermeria",
      value: "2",
      field: (
        <div className=" mt-2">
          <label htmlFor="pizza">Profesion</label >
          <select className="form-control" defaultValue="Licenciado" onChange={event => setFormData({
            name: event.target.name,
            value: event.target.value,
          })} name="subtipo">
            <option value="Licenciado">Licenciado</option>
            <option value="Tecnico">Tecnico</option>
            <option value="Cuidador">Cuidador</option>
            <option value="Fisioterapsita">Fisioterapsita</option>
            <option value="Auxiliar">Auxiliar</option>


          </select>
        </div>
      )
    },
    {
      label: "Logistica",
      value: "3",
    },

  ]);
  const [dishes2] = useState([

    {
      label: "No",
      value: "No",
    },
    {
      label: "Si",
      value: "Si",
      field: (
        <div className=" mt-2">
          <label htmlFor="pizza">Definir alergias a Medicamentos</label >
          <div className="">
            <input
              type="text"
              className="form-control"

              placeholder="Definir Alergias"
              name="definiralergia"
              onChange={handleChange2}
            />
          </div>
        </div>
      )
    },
    {
      label: "No Define",
      value: "No Define",
    },

  ]);
  const [dishes3] = useState([

    {
      label: "No",
      value: "No",
    },
    {
      label: "Si",
      value: "Si",
      field: (
        <div className=" mt-2">
          <label htmlFor="pizza">Definir alergias en Gral.</label >
          <div className="">
            <input
              type="text"
              className="form-control"

              placeholder="Definir Alergias"
              name="definiralergiasgral"
              onChange={handleChange2}
            />
          </div>
        </div>
      )
    },
    {
      label: "No Define",
      value: "No Define",
    },

  ]);

  const [selectedDish, setSelectedDish] = useState(dishes[0]);
  const [selectedDish2, setSelectedDish2] = useState(dishes2[0]);
  const [selectedDish3, setSelectedDish3] = useState(dishes3[0]);
  const {
    children,
    estado,
    x,
    typeModal,
    legajoObject,
    onCloseModal,
    onReturnValue,
  } = props;
  const pacienteRef = useRef();
  const [listPaciente, setListPaciente] = useState([]);
  const [listTipoUsuario, setListTipoUsuario] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [formData2, setFormData2] = useReducer(formReducer, {});
  const [listPaciente2, setListPaciente2] = useState([]);
  const onLoadTipoUsuario = useCallback(async () => {
    const result = await getAllTipoUsuarios();
    setListTipoUsuario(result);
  });

  const loadedPaciente = useCallback(async () => {

    const userData = await getUserCredentials();
    const userData2 = await getUserAsignaciones(userData.IdUsuario);

    let result = [];
    result = await getAllPaciente();
    const unicos = [];
    for (var i = 0; i < userData2.length; i++) {

      const elemento = userData2[i].IdPaciente;

      if (!unicos.includes(userData2[i].IdPaciente)) {
        unicos.push(elemento);
      }
    }


    for (var i = 0; i < unicos.length; i++) {
      for (var j = 0; j < result.length; j++) {
        if (unicos[i] == result[j].IdPacientes)
          setListPaciente2(listPaciente2 => [...listPaciente2, { value: result[j].IdPacientes, label: result[j].NombrePaciente }])
      }
    }

    setisLoading(false)


  }, []);

  useEffect(() => {
    loadedPaciente();

    if (typeModal === "USUARIO") {
      onLoadTipoUsuario();
    }
  }, [typeModal]);

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(false);

  const success = (pos) => {

    let dataReturn = {};
    if (typeModal === "ENTRADA") {

      dataReturn = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        IdPacientes: pacienteRef.current.IdPacientes,
        NombrePaciente: pacienteRef.current.NombrePaciente,
      };
    } else if (typeModal === "SALIDA") {

      dataReturn = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        IdPacientes: legajoObject.IdPaciente,
        NombrePaciente: legajoObject.Paciente,
        IdLegajoEnfermeria: legajoObject.IdLegajoEnfermeria,
      };
    }
    onReturnValue(dataReturn);
  };


  const  error =  async(err) => {
    Swal.fire({
      icon: "error",
      title: `${err.message}`,
     

    });
    
  }

  const onMarcar = () => {
    setmarcarEntrada(true)
    try {
      Swal.fire({
        title: 'Cargando Ubicacion',
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
            Swal.showLoading()
        },
    })
      navigator.geolocation.getCurrentPosition(success, error, options);
      Swal.close()
      navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
        if (permissionStatus.state === 'denied') {
          window.location.href = "app-settings:location";
        }
      });
      
    } catch (err) {

    }
  };

  const pacienteHadler = (event) => {
    console.log(event)
    pacienteRef.current = {
      IdPacientes: event.value,
      NombrePaciente: event.label,
    };
  };


  if (typeModal === "ENTRADA") {
    return (
      <>
        <Modal show={estado} onHide={onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Marcar Entrada</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <form
                    id="mentrada"
                    onSubmit={(e) => {
                      e.preventDefault();
                      onMarcar();
                    }}
                  >
                    <div className="row">
                      <div className="form-group mb-2">
                        <label>Usuario del servicio</label>

                        <Select
                          placeholder='Usuarios del Servicio'
                          options={listPaciente2}
                          isLoading={isLoading}
                          onChange={pacienteHadler}
                          noOptionsMessage={() => "Sin Opciones"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onCloseModal}>Cerrar</Button>
            <Button type="submit" form="mentrada" disabled={marcarEntrada}>
              Marcar
            </Button>
          </Modal.Footer>
        </Modal>
        {children}
      </>
    );
  }

  if (typeModal === "SALIDA") {
    return (
      <>
        <Modal show={estado} onHide={onCloseModal}>
          <Modal.Header>
            <Modal.Title>Marcar Salida</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="form-group mb-2">
                        <label>Cliente</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="paciente"
                            value={legajoObject.Paciente}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onCloseModal}>Cerrar</Button>
            <Button onClick={onMarcar}>Marcar</Button>
          </Modal.Footer>
        </Modal>
        {children}
      </>
    );
  }

  const usuarioHandleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const onSubmitUsuario = (e) => {
    e.preventDefault();

    formData.tipousuario = formData.tipousuario ? formData.tipousuario : "2";
    formData.subtipo = formData.subtipo ? formData.subtipo : "Licenciado";
    formData.sexo = formData.sexo ? formData.sexo : "Hombre";
    formData.estadocivil = formData.estadocivil ? formData.estadocivil : "Soltero/a";




    formData.Fechadeingreso = formData.Fechadeingreso ? formData.Fechadeingreso : ""
    formData.RegistroProfesional = formData.RegistroProfesional ? formData.RegistroProfesional : ""



    formData.hijos = formData.hijos ? formData.hijos : ""

    formData.antpol = formData.antpol ? formData.antpol : ""

    formData.departamento = formData.departamento ? formData.departamento : ""

    formData.barrio = formData.barrio ? formData.barrio : ""
    formData.direccion = formData.direccion ? formData.direccion : ""

    onReturnValue(formData);
  };
  const handleDishSelect = (e) => {
    setFormData({
      name: e.target.name,
      value: e.target.value,
    });
    const dish = dishes.find((dish) => dish.value === e.target.value);
    if (dish) {
      setSelectedDish(dish);
    }
  };
  const handleDishSelect2 = (e) => {
    setFormData2({
      name: e.target.name,
      value: e.target.value,
    });

    const dish2 = dishes2.find((dish2) => dish2.value === e.target.value);
    if (dish2) {
      setSelectedDish2(dish2);
    }
  };
  const handleDishSelect3 = (e) => {
    setFormData2({
      name: e.target.name,
      value: e.target.value,
    });
    const dish3 = dishes3.find((dish3) => dish3.value === e.target.value);
    if (dish3) {
      setSelectedDish3(dish3);
    }
  };
  if (typeModal === "USUARIO") {
    return (
      <>
        <Modal show={estado} onHide={onCloseModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Agregar Cuenta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-body">
                <div className="form-validation">
                  <form
                    className="form-valide"
                    action="#"
                    method="post"
                    onSubmit={(e) => {
                      onSubmitUsuario(e);
                    }}
                    id="userform"
                  >
                    <div className="row">
                      <div className="col">
                        <label className="col-form-label">
                          <h3>Cuenta</h3>
                        </label>

                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Foto <span className="text-danger">Maximo 1mb</span>

                          </label>

                          <input
                            accept='image/*'
                            type='file'
                            style={{ display: 'none' }}
                            ref={referencia}
                            onChange={(e) => {
                              const file = e.target.files[0]

                              if (file && file.type.substring(0, 5) === 'image' && e.target.files[0].size < 1 * 5000 * 1024) {
                                setImage(file)
                              } else {
                                setImage(null)
                                Swal.fire({
                                  title: "Imagen Invalida o mayor a 5mb",
                                  allowOutsideClick: false,
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                });
                              }
                              setFormData({
                                name: 'imagen',
                                value: file,
                              });




                            }}
                          ></input>
                          <div className="container mt-3" style={{ display: 'flex' }}>
                            {
                              image
                                ?
                                <div className="card"> <img src={preview} onClick={uploadFiles} className="card-img-top" style={{ height: "350px", width: "300px", cursor: 'pointer' }}></img></div>
                                :
                                <UploadAnimation uploadFiles={uploadFiles}></UploadAnimation>
                            }
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Usuario
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Usuario"
                              required
                              name="usuario"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Contraseña
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Contraseña"
                              required
                              name="password"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2  row ">
                          <label className="form-label">
                            Cargo
                            <span className="text-danger">*</span>
                          </label>
                          <div className=" mb-2">
                            <select onChange={handleDishSelect} className="form-control" name="tipousuario">
                              {dishes.map((dish) => (
                                <option key={dish.value} value={dish.value}>
                                  {dish.label}
                                </option>
                              ))}
                            </select>
                            {selectedDish && selectedDish.field}




                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Fecha de Ingreso

                          </label>
                          <div className="">
                            <input
                              type="date"
                              className="form-control"

                              name="Fechadeingreso"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <label className="col-form-label">
                          <h3>Datos Personales</h3>

                        </label>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Registro Profesional

                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"

                              placeholder="Registro Profesional"
                              name="RegistroProfesional"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Nombre
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              required
                              placeholder="Nombre"
                              name="nombre"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Apellido
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              required
                              placeholder="Apellido"
                              name="apellido"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            CI
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="number"
                              className="form-control"
                              required
                              placeholder="CI"
                              name="cedula"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2  row ">
                          <label className="form-label">Sexo</label>
                          <div className="">
                            <select
                              className="form-control"
                              defaultValue="Hombre"
                              name="sexo"
                              onChange={usuarioHandleChange}

                            >
                              <option value="Hombre">Hombre</option>
                              <option value="Mujer">Mujer</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-2  row ">
                          <label className="form-label">Estado Civil</label>
                          <div className="">
                            <select
                              className="form-control"
                              defaultValue="Soltero/a"
                              name="estadocivil"
                              onChange={usuarioHandleChange}

                            >
                              <option value="soltero/a">Soltero/a</option>
                              <option value="casado/a">Casado/a</option>
                              <option value="Divorciado/a">Divorciado/a</option>
                              <option value="Viudo/a">Viudo/a</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Hijos

                          </label>
                          <div className="">
                            <input
                              type="number"
                              className="form-control"

                              placeholder="Hijos"
                              name="hijos"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Fecha de Nacimiento
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              className="form-control"
                              required
                              name="Fechadenacimiento"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Ant. Policial

                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"

                              placeholder="Ant. Policial"
                              name="antpol"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <label className="col-form-label">
                          <h3> Contacto</h3>
                        </label>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Telefono
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="number"
                              className="form-control"
                              required
                              placeholder="Telefono"
                              name="telefono"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Departamento

                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"

                              placeholder="Departamento"
                              name="departamento"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Ciudad
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              required
                              placeholder="Ciudad"
                              name="ciudad"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Barrio

                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"

                              placeholder="Barrio"
                              name="barrio"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 row">
                          <label className="col-form-label">
                            Direccion

                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"

                              placeholder="Direccion"
                              name="direccion"
                              onChange={usuarioHandleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onCloseModal}>Cerrar</Button>
            <Button type="submit" form="userform">
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
        {children}
      </>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setShow(!show);
    setShow2(!show2);
  };
  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();
    formData.nivel = formData2.nivel ? formData.nivel : "Nivel 1";
    formData.sexo = formData2.sexo ? formData.sexo : "Hombre";
    formData2.formadealimentacion = formData2.formadealimentacion ? formData2.formadealimentacion : "No Asistida"
    formData2.equipoderehabilitacion = formData2.equipoderehabilitacion ? formData2.equipoderehabilitacion : "Sin Equipo"
    formData2.mpropia = formData2.mpropia ? formData2.mpropia : "No Asistida"
    formData2.viadealimentacion = formData2.viadealimentacion ? formData2.viadealimentacion : "Oral"
    formData2.alergiasamedicamentos = formData2.alergiasamedicamentos ? formData2.alergiasamedicamentos : "No"
    formData2.alergiasgral = formData2.alergiasgral ? formData2.alergiasgral : "No"
    if (formData2.alergiasgral == "Si") {
      formData2.alergiasgral = formData2.definiralergiasgral
    }
    if (formData2.alergiasamedicamentos == "Si") {
      formData2.alergiasamedicamentos = formData2.definiralergia
    }
    onReturnValue(Object.assign(formData, formData2));
  };

  if (typeModal === "CLIENTE") {
    return (
      <>
        <Modal show={estado} onHide={onCloseModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Agregar Cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {show && (
              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={handleSubmit}
                id="Clienteform"
              >
                <Row>

                  <Col>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Nombre
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="Nombre"
                          name="nombre"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Apellido
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="Apellido"
                          name="apellido"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        CI
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          required
                          placeholder="CI"
                          name="ci"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Telefono
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          required
                          pattern="[0-9]{7,12}"
                          title="Ingrese Numero de telefono Valido "
                          placeholder="Telefono"
                          name="telefono"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">Correo</label>
                      <div className="">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Correo"
                          name="correo"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Ciudad
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ciudad"
                          name="ciudad"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Direccion

                      </label>

                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Direccion"
                          name="direccion"

                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Responsable
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Responsable"
                          name="responsable"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        CI del Responsable
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="CI del Responsable"
                          name="ciresponsable"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Numero del Responsable
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          pattern="[0-9]{7,12}"
                          title="Ingrese Numero de telefono Valido "
                          placeholder="Numero del Responsable"
                          name="nresponsable"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Razon social
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Razon Social"
                          name="razonsocial"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Ruc
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ruc"
                          name="ruc"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Tarifa
                      </label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Tarifa"
                          name="tarifa"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2  row ">
                      <label className="form-label">Nivel</label>
                      <div className="">
                        <select
                          className="form-control"
                          defaultValue="Hombre"
                          name="nivel"
                          onChange={handleChange2}

                        >
                          <option value="Nivel 1" style={{color:"green"}}>Nivel 1</option>
                          <option value="Nivel 2" style={{color:"orange"}}>Nivel 2</option>
                          <option value="Nivel 3" style={{color:"red"}}>Nivel 3</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-form-label"
                        htmlFor="val-password"
                      >
                        Como Conocio EethSA
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Como Conocio Eethsa"
                          name="comoconocio"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label">
                        Observacion
                      </label>
                      <div className="">
                        <textarea
                          name="obs1"

                          className="form-control"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                  </Col>

                </Row>
              </form>



            )}
            {show2 && (

              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={handleSubmit2}
                id="Clienteform2"
              >
                <Row>
                  <Col>

                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Fecha de Nacimiento
                      </label>
                      <div className="">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Fecha de Nacimiento"
                          name="fechadenacimiento"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Peso (en Kg)
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Peso"
                          name="peso"
                          pattern="(?<=^| )\d+(\.\d+)?(?=$| )"
                          title="Ingrese valor en Kg ejemplo (75.80)"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Altura (en m )
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Altura"
                          name="altura"
                          pattern="(?<=^| )\d+(\.\d+)?(?=$| )"
                          title="Ingrese valor en m ejemplo (1.80)"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2  row ">
                      <label className="form-label">Sexo</label>
                      <div className="">
                        <select
                          className="form-control"
                          defaultValue="Hombre"
                          name="sexo"
                          onChange={handleChange2}

                        >
                          <option value="Hombre">Hombre</option>
                          <option value="Mujer">Mujer</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Diagnostico Medico
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Diagnostico Medico"
                          name="diagnosticomedico"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Enfermedades de Base
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enfermedades de Base"
                          name="enfermedadesdebase"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2  row ">
                      <label className="form-label">
                        Movilidad
                      </label>
                      <div className="">
                        <select
                          className="form-control"
                          defaultValue="No Asistida"
                          name="mpropia"
                          onChange={handleChange2}

                        >
                          <option value="No Asistida">No Asistida</option>
                          <option value="Asistida">Asistida</option>
                          <option value="Dependiente">Dependiente</option>
                          <option value="Semi Independiente">Semi Independiente</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Equipo de Rehabilitacion
                      </label>
                      <div className="">
                        <select
                          className="form-control"
                          defaultValue="Sin Equipo"
                          name="equipoderehabilitacion"
                          onChange={handleChange2}

                        >
                          <option value="Sin Equipo">Sin Equipo</option>
                          <option value="Baston">Baston</option>
                          <option value="Silla de Ruedas">Silla de Ruedas</option>
                          <option value="Andador">Andador</option>
                          <option value="Grua">Grua</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Via de alimentación/medicacion
                      </label>
                      <div className="">
                        <select
                          className="form-control"
                          defaultValue="Oral"
                          name="viadealimentacion"
                          onChange={handleChange2}

                        >
                          <option value="Oral">Oral</option>
                          <option value="Gastrotomia">Gastrotomia</option>
                          <option value="Sonda Nagastrica">Sonda Nagastrica</option>
                          <option value="Sonda Nasoyeyunal">Sonda Nasoyeyunal</option>

                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Forma de Alimentacion/Medicacion
                      </label>
                      <div className="">
                        <select
                          className="form-control"
                          id="val-skill"
                          name="formadealimentacion"
                          onChange={handleChange2}
                          defaultValue="No Asistida"
                        >
                          <option value="No Asistida">No Asistida</option>
                          <option value="Asistida">Asistida</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group mb-2  row ">
                      <label className="form-label">
                        Alergias a Medicamentos

                      </label>
                      <div className=" mb-2">
                        <select onChange={handleDishSelect2} className="form-control" name="alergiasamedicamentos">
                          {dishes2.map((dish2) => (
                            <option key={dish2.value} value={dish2.value}>
                              {dish2.label}
                            </option>
                          ))}
                        </select>
                        {selectedDish2 && selectedDish2.field}




                      </div>
                    </div>
                    <div className="form-group mb-2  row ">
                      <label className="form-label">
                        Alergias en Gral.

                      </label>
                      <div className=" mb-2">
                        <select onChange={handleDishSelect3} className="form-control" name="alergiasgral">
                          {dishes3.map((dish3) => (
                            <option key={dish3.value} value={dish3.value}>
                              {dish3.label}
                            </option>
                          ))}
                        </select>
                        {selectedDish3 && selectedDish3.field}




                      </div>
                    </div>
                    <div className="form-group mb-2 row">
                      <label className="col-form-label">
                        Tiempo de Asistencia
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tiempo de Asistencia"
                          name="tiempodeasistencia"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label">
                        Modalidad de guardia (Hs).
                      </label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Hs."
                          name="hs"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label">
                        Direccion Para el Servicio
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Direccion"
                          name="direccionserv"

                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label">
                        Observacion
                      </label>
                      <div className="">
                        <textarea
                          name="obs2"

                          className="form-control"
                          onChange={handleChange2}
                        />
                      </div>
                    </div>

                  </Col>
                </Row>
              </form>

            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onCloseModal}>Salir</Button>
            {show && (
              <Button type="Submit" form="Clienteform">
                Siguiente
              </Button>
            )}
            {show2 && (
              <Button type="submit" form="Clienteform2">
                Guardar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        {children}
      </>
    );
  }
};
export default Modale;
