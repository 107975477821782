
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import Headerimg from '../../../../utils/header.jpeg'
import Footerimg from '../../../../utils/footer.jpeg'




export default function genPDF(props) 
  {
    const {datos,cargo,edad,mescumple,preview,isShow} = props;
    var doc = new jsPDF();
    let tipouso
    if (isShow? tipouso=" Uso Interno" : tipouso=" Uso Externo")
    var totalPagesExp = "{total_pages_count_string}";
    console.log(datos)
    doc.autoTable({
      didDrawPage: function(data) {
        
        
        doc.setTextColor(40);
        doc.setFontSize(15);
        let derecha = 70+10+10
        let izquierda = 150+10+10
        doc.addImage(`${preview}`, 'JPEG',data.settings.margin.left+5+5-5, 65+20, 70,70);
        doc.text("Datos Personales", data.settings.margin.left+5+5 + 90, derecha);
        // datos personales
        doc.setFontSize(12);
        doc.text("Nombre Completo", data.settings.margin.left+5+5-3 + 75, derecha+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.Nombre}`, data.settings.margin.left+5+5-3 + 115, derecha +7);
        doc.setFontSize(12);
        doc.text("Cedula", data.settings.margin.left+5+5-3+ 75, derecha+7+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.Cedula}`, data.settings.margin.left+5+5-3+ 115, derecha+7+7);
        doc.setFontSize(12);
        doc.text("Sexo", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.sexo}`, data.settings.margin.left+5+5 -3+ 115, derecha+7+7+7);
        doc.setFontSize(12);
        doc.text("Estado Civil", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.estadocivil}`, data.settings.margin.left+5+5 -3+ 115, derecha+7+7+7+7);
        doc.setFontSize(12);
        doc.text("Hijos", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.hijos}`, data.settings.margin.left+5+5 -3+ 115, derecha+7+7+7+7+7);
        doc.setFontSize(12);
        doc.text("Fecha de Nacimiento", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.Fechadenacimiento}`, data.settings.margin.left+5+5 -3+ 115, derecha+7+7+7+7+7+7);
        doc.setFontSize(12);
        doc.text("Edad", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7+7+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${edad}`, data.settings.margin.left+5+5 -3+ 115, derecha+7+7+7+7+7+7+7);
        doc.setFontSize(12);
        doc.text("Mes de Cumpleaños", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7+7+7+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${mescumple}`, data.settings.margin.left+5+5-3 + 115, derecha+7+7+7+7+7+7+7+7);
        doc.setFontSize(12);
        doc.text("Antecedente Policial", data.settings.margin.left+5+5 -3+ 75, derecha+7+7+7+7+7+7+7+7+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.antpol}`, data.settings.margin.left+5+5-3 + 115, derecha+7+7+7+7+7+7+7+7+7);


        doc.setFontSize(15);
        doc.text("Cuenta", data.settings.margin.left+5+5+20 ,izquierda);
        // Cuenta 
       
        doc.setFontSize(12);
        doc.text("Usuario", data.settings.margin.left+5+5 ,izquierda +10);
        doc.setFontSize(10);
        doc.text(`: ${datos.Usuario}`, data.settings.margin.left+5+5+40,izquierda+10);

        doc.setFontSize(12);
        doc.text("Cargo", data.settings.margin.left+5+5 ,izquierda+10+7);
        doc.setFontSize(10);
        doc.text(`: ${cargo}`, data.settings.margin.left+5+5+40,izquierda+10+7);

        doc.setFontSize(12);
        doc.text("Profesion", data.settings.margin.left+5+5 ,izquierda+10+14);
        doc.setFontSize(10);
        doc.text(`: ${datos.subtipo}`, data.settings.margin.left+5+5+40,izquierda+10+14);

        doc.setFontSize(12);
        doc.text("Registro Profesional", data.settings.margin.left+5+5,izquierda+10+21);
        doc.setFontSize(10);
        doc.text(`: ${datos.RegistroProfesional}`, data.settings.margin.left+5+5+40,izquierda+10+21);
        if (isShow)
         {
          doc.setFontSize(12);
          doc.text("Fecha de ingreso", data.settings.margin.left+5+5 ,izquierda+10+28);
          doc.setFontSize(10);
          doc.text(`: ${datos.Fechaingreso}`, data.settings.margin.left+5+5+40,izquierda+10+28);
         }
       

        // Contacto
        doc.setFontSize(15);
        doc.text("Contacto", data.settings.margin.left+5+5+3 + 90, derecha+80);



        doc.setFontSize(12);
        doc.text("Departamento", data.settings.margin.left+5+5-3 + 75, derecha+90);
        doc.setFontSize(10);
        doc.text(`: ${datos.departamento}`, data.settings.margin.left+5+5-3 + 115, derecha+90);
        doc.setFontSize(12);
        doc.text("Ciudad", data.settings.margin.left+5+5-3+ 75, derecha+90+7);
        doc.setFontSize(10);
        doc.text(`: ${datos.Ciudad}`, data.settings.margin.left+5+5-3+ 115,derecha+90+7);
        doc.setFontSize(12);
        doc.text("Barrio", data.settings.margin.left+5+5 -3+ 75, derecha+90+14);
        doc.setFontSize(10);
        doc.text(`: ${datos.barrio}`, data.settings.margin.left+5+5 -3+ 115,derecha+90+14);


         if (isShow)
         {
          doc.setFontSize(12);
          doc.text("Direccion", data.settings.margin.left+5+5-3+ 75, derecha+90+14+7);
          doc.setFontSize(10);
          doc.text(`: ${datos.direccion}`, data.settings.margin.left+5+5-3+ 115, derecha+90+14+7);
          doc.setFontSize(12);
          doc.text("Numero", data.settings.margin.left+5+5 -3+ 75, derecha+90+14+14);
          doc.setFontSize(10);
          doc.text(`: ${datos.Telefono}`, data.settings.margin.left+5+5 -3+ 115,derecha+90+14+14);
         }
       


        // Header 
        // Footer
        doc.setFontSize(20);
        doc.addImage(`${Headerimg}`, 'JPEG', 0, 0, 210,40);
        doc.addImage(`${Footerimg}`, 'JPEG', 0, 255, 210,40)
        doc.text("Ficha Profesional EETHSA", data.settings.margin.left+5 + 50, 65);
        doc.setTextColor(97,112, 123);
        doc.setFontSize(10);
        if (isShow){
          doc.text(`${tipouso}`, data.settings.margin.left+5+10+20+50, 70);
        }
        
        var str = "Page " + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left+5+5, pageHeight - 10);
      },
      margin: {
        top: 30
      }
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(`${datos.Nombre}-${datos.Cedula}-${tipouso}.pdf`);
  }

